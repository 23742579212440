import clsx from "clsx";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import Container from "../../Components/Container";
import useOptions from "../../Hooks/useOptions";

interface IObservationHeaderItemProps {
  id: number;
  country_name: string;
  ioc_finnish: string;
  ioc_english: string;
  ioc_scientific: string;
  scientific_abbreviation: string;
  municipality_name: string;
  association_name: string;
  additional_info: string;
  date_from: string;
  date_to: string;
  keyword_ids: string;
  tooltip?: string;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
}

// KeywordIndexResponse
function ObservationHeaderItem(
  props: IObservationHeaderItemProps
) {
  const {
    id,
    country_name,
    municipality_name,
    additional_info,
    association_name,
    date_from,
    date_to,
    keyword_ids,
    onEdit,
    onDelete,
    tooltip,
  } = {
    ...props,
  };

  const { getCollectionItemsFields } = useOptions();
  const [searchparams] = useSearchParams();

  const dateToReadable = (
    date_from: string,
    date_to: string
  ) => {
    if (!date_from) return "0.0.0000";
    //if both then return between
    if (date_to && date_from !== date_to) {
      return `${format(
        new Date(date_from),
        "dd.MM.yyyy"
      )} - ${format(new Date(date_to), "dd.MM.yyyy")}`;
    }
    return format(new Date(date_from), "dd.MM.yyyy");
  };

  const cutAdditionalInfo = (info: string) => {
    if (!info) return "";
    const maxChars = 100;
    if (info.length > maxChars) {
      return `${info.substring(0, maxChars - 3)}...`;
    }
    return info;
  };

  const getKeywordNames = () => {
    if (!keyword_ids || keyword_ids === "") return "";
    const keywordIds = keyword_ids.split(",");
    const keywords = getCollectionItemsFields(
      "keywords",
      (item) => {
        return keywordIds.includes(item.id.toString());
      },
      "name"
    );
    return keywords.join(", ");
  };

  const getPlace = () => {
    if (municipality_name) return municipality_name;
    if (association_name) return association_name;
    if (country_name) return country_name;
    return "";
  };

  const getSpeciesByLanguageSelected = () => {
    const language = searchparams.get("language");
    if (language === "ioc_english") return props.ioc_english;
    if (language === "ioc_finnish") return props.ioc_finnish;
    if (language === "ioc_scientific")
      return props.ioc_scientific;
    if (language === "scientific_abbreviation")
      return props.scientific_abbreviation;
    return props.ioc_finnish || "...";
  };

  const infoToString = () => {
    /** Only add field if its not empty string */
    let result = `${
      getSpeciesByLanguageSelected() ||
      props.ioc_finnish ||
      "..."
    } |`;
    if (getPlace()) result += ` ${getPlace()} |`;
    if (date_from)
      result += ` ${dateToReadable(date_from, date_to)} |`;
    if (additional_info)
      result += ` ${cutAdditionalInfo(additional_info)} |`;
    if (getKeywordNames()) result += ` ${getKeywordNames()}`;
    if (result.endsWith("|")) result = result.slice(0, -1);
    return result;
  };

  const openTooltip = () => {
    if (tooltip) {
      Swal.fire({
        html: tooltip,
      });
    }
  };

  return (
    <motion.div
      id={`header-div-${id}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn", delay: 0 }}
    >
      <Container
        className={clsx("observation-container py-2 my-2", {
          highlight: searchparams.get("edit") === id.toString(),
        })}
      >
        <div className="d-flex justify-content-start w-75">
          <p
            className="m-0"
            style={{ overflowX: "hidden" }}
            title={additional_info}
          >
            {infoToString()}
          </p>
        </div>
        <div className="d-flex justify-content-end w-25">
          {tooltip && (
            <i
              className="bi bi-info-circle-fill px-2 icon-hover"
              title={"Lisätietoja"}
              onClick={openTooltip}
            ></i>
          )}
          <i
            className="bi bi-pencil-fill px-2 icon-hover"
            title={"Muokkaa havaintoa"}
            onClick={() => {
              onEdit(id);
            }}
          ></i>
          <i
            className="bi bi-trash-fill px-2 icon-hover"
            title={"Poista havainto"}
            onClick={() => onDelete(id)}
          ></i>
        </div>
      </Container>
    </motion.div>
  );
}

export default ObservationHeaderItem;
