import clsx from "clsx";
import "../../Styles/FInputs.scss";

interface IFRadioButtonProps {
  text?: string;
  checkked: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

/**Completely custom radio button that is actually a div */
function FRadioButton(props: IFRadioButtonProps) {
  const { text, checkked, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={clsx("custom-radio-option", {
        selected: checkked,
      })}
    >
      <label className="m-0 p-0 px-2">{text}</label>
    </div>
  );
}

export default FRadioButton;
