import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import CSVDownloadButton from "../../Components/CSVDownloadButton";
import Container from "../../Components/Container";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {
  FilteredObservationResponse,
  OwnObservationResponse,
} from "../../Types/apiResponses";
import {
  deleteObservation,
  fetchFlaggedObservations,
  fetchOwnObservations,
} from "../../Utils/apiUtils";
import ObservationHeaderItem from "./ObservationHeaderItem";
import "./styles.scss";

function ObservationEditorList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const [page, setPage] = useState<
    "observations" | "taxons" | "flags"
  >("observations");

  const observationQuery = useQuery(
    "own-observations",
    () => fetchOwnObservations(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const taxonCheckQuery = useQuery(
    "taxon-check",
    () => fetchOwnObservations(true),
    {
      refetchOnWindowFocus: false,
    }
  );

  const flaggedQuery = useQuery(
    "flagged-observations",
    () => fetchFlaggedObservations(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleEdit = (id: number) => {
    let observation;
    if (page === "observations") {
      if (!observationQuery.data) return;
      observation = observationQuery.data.find(
        (obs: OwnObservationResponse) => obs.id === id
      );
    }
    if (page === "taxons") {
      if (!taxonCheckQuery.data) return;
      observation = taxonCheckQuery.data.find(
        (obs: OwnObservationResponse) => obs.id === id
      );
    }
    if (page === "flags") {
      if (!flaggedQuery.data) return;
      observation = flaggedQuery.data.reports.find(
        (obs: FilteredObservationResponse) => obs.id === id
      );
    }

    if (!observation) return;

    const newSearch = new URLSearchParams();
    newSearch.set("edit", observation.id.toString());
    // newSearch.set("language", searchParams.get("language") || "ioc_finnish");

    newSearch.delete("language");
    if (observation.is_wp === 1) {
      newSearch.set("area", "1");
    } else if (observation.is_wp === 0) {
      newSearch.set("area", "2");
    } else {
      newSearch.set("area", "3");
    }
    newSearch.set("species", observation.species_id.toString());
    if (observation.municipality_id) {
      newSearch.set(
        "municipality",
        observation.municipality_id.toString()
      );
    }
    if (observation.association_id) {
      newSearch.set(
        "association",
        observation.association_id.toString()
      );
    }
    if (observation.country_id) {
      newSearch.set(
        "country",
        observation.country_id.toString()
      );
    }
    if (observation.continent_id) {
      newSearch.set(
        "continent",
        observation.continent_id.toString()
      );
    }
    if (observation.additional_info) {
      newSearch.set(
        "additional_info",
        observation.additional_info
      );
    }
    if (observation.keyword_ids) {
      newSearch.set("keywords", observation.keyword_ids);
    }
    if (
      observation.date_from !== observation.date_to ||
      !observation.date_to
    ) {
      newSearch.set("date", "-1");
    } else if (observation.date_from) {
      const dateInFormat = format(
        new Date(observation.date_from),
        "yyyy-MM-dd"
      );
      newSearch.set("date", dateInFormat);
    }
    setSearchParams(newSearch, { replace: true });
  };

  const deleteMutation = useMutation(
    (id: any) => deleteObservation(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("own-observations");
        queryClient.invalidateQueries("taxon-check");
        queryClient.invalidateQueries("flagged-observations");
        observationQuery.refetch();
        taxonCheckQuery.refetch();
        flaggedQuery.refetch();
      },
    }
  );

  const promptForDelete = (id: number) => {
    /**Swal prompt on ok deleteMutation.mutate */
    Swal.fire({
      title: "Haluatko varmasti poistaa havainnon?",
      text: "Havaintoa ei voi palauttaa!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "grey",
      confirmButtonText: "Kyllä",
      cancelButtonText: "Peruuta",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMutation.mutate(id);
      }
    });
  };

  if (!observationQuery.isSuccess) {
    return (
      <Container>
        <div className="row w-100 m-0 p-0 justify-content-center my-5">
          <h4 className="text-center m-0 p-0">
            Ladataan omia havaintoja...
          </h4>
          <LoadingSpinner />
        </div>
      </Container>
    );
  }

  const getCurrentCollecitonByPage = () => {
    if (page === "observations") {
      return observationQuery.data || [];
    } else if (page === "taxons") {
      return taxonCheckQuery.data || [];
    } else if (page === "flags") {
      return flaggedQuery.data?.reports || [];
    }
    return [];
  };

  return (
    <Container style={{ minHeight: "240px" }}>
      <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6 my-2 p-0 m-0">
        <div className="container w-100 gap-2">
          <div className="row w-100 m-0 p-0">
            <div className="col-6 col-sm-3 m-0 my-1 p-0 px-1">
              <button
                onClick={() => {
                  setPage("observations");
                }}
                className="w-100 m-0 p-2 text-md"
              >
                Havainnot
              </button>
            </div>
            <div className="col-6 col-sm-3 m-0 my-1 p-0 px-1">
              <button
                onClick={() => {
                  setPage("taxons");
                }}
                className="w-100 m-0 p-2 text-md"
              >
                Tarkistettavat
              </button>
            </div>
            <div className="col-6 col-sm-3 m-0 my-1 p-0 px-1">
              <button
                onClick={() => {
                  setPage("flags");
                }}
                className="w-100 m-0 p-2 text-md"
              >
                Liputetut ({flaggedQuery.data?.report_count || 0}
                )
              </button>
            </div>
            <div className="col-6 col-sm-3 m-0 my-1 p-0 px-1">
              <CSVDownloadButton />
            </div>
          </div>
        </div>

        <div className="container w-100">
          <AnimatePresence>
            {getCurrentCollecitonByPage()?.length > 0 ? (
              getCurrentCollecitonByPage().map(
                (observation: any) => (
                  <ObservationHeaderItem
                    tooltip={
                      observation.message
                        ? `
                        <b>Liputtaja:</b> ${
                          observation.firstname
                        } ${observation.lastname}</br>
                        <b>Liputettu:</b> ${format(
                          new Date(observation.created_at),
                          "d MMMM yyyy",
                          { locale: fi }
                        )}</br></br>
                        <b>Viesti:</b></br> "${
                          observation.message
                        }"
                          `
                        : ""
                    }
                    key={observation.id}
                    onEdit={handleEdit}
                    onDelete={promptForDelete}
                    {...observation}
                  />
                )
              )
            ) : (
              <div className="w-100 mt-5 text-center">
                <h5>Ei näytettävää</h5>
              </div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Container>
  );
}

export default ObservationEditorList;
