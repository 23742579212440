import clsx from "clsx";
import {
  AnimatePresence,
  Reorder,
  motion,
  useDragControls,
} from "framer-motion";
import { get as lsGet, remove as lsRemove } from "local-storage";
import {
  filter as _filter,
  find as _find,
  map as _map,
  isArray,
} from "lodash";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import Container from "../../Components/Container";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useCheckLastVisit from "../../Hooks/useLastVisit";
import useUserSettings, {
  UserListOrder,
  isUserSettings,
} from "../../Hooks/useUserSettings";
import { listIndexResponse } from "../../Types/apiResponses";
import {
  applySettings,
  fetchKeywords,
  fetchLists,
  fetchListsByIds,
  getSettings,
  postJoinList,
  postLeavelList,
} from "../../Utils/apiUtils";
import {
  createPairsFromSearchQuery,
  createSearchQueryFromPairs,
  parseFormData,
} from "../../Utils/generalUtils";
import OrderSettings from "./OrderSettings";
import PinnaListHeaderItem from "./PinnaListHeaderItem";
import PinnaListPageFilterForm from "./PinnaListPageFilterForm";
import "./styles.scss";

/** Pinna list page body */
function PinnaListsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchparams, setSearchParams] = useSearchParams();
  const [showOwnOrder, setShowOwnOrder] = useState(true);
  const [showOrderSettings, setShowOrderSettings] =
    useState<boolean>(false);
  const saveSettingsTimerRef = useRef<any>(null);

  const {
    loadedOrder,
    userSettings,
    changeLoadedOrder,
    loadUserSettingsFromLs,
    addUserListOrder,
    removeUserListOrder,
    toggleHiddenList,
    getHiddenLists,
    setOrderAsDefault,
    forceUserSettings,
  } = useUserSettings();

  useEffect(() => {
    loadUserSettingsFromLs();
  }, []);

  const keywordQuery = useQuery("keywords", fetchKeywords, {
    refetchOnWindowFocus: false,
  });

  const listQuery = useQuery(
    ["lists", createPairsFromSearchQuery(location.search)],
    () => fetchLists(location.search),
    {
      refetchOnWindowFocus: false,
    }
  );

  const currentOrder = () => {
    const order = loadedOrder?.order || [];
    return order ? [...order].sort((a, b) => a - b) : [];
  };

  const ownListOrderQuery = useQuery(
    ["own-ordered-lists", currentOrder()],
    () => fetchListsByIds(currentOrder() || []),
    {
      refetchOnWindowFocus: false,
      enabled: currentOrder().length > 0,
    }
  );

  const userSettingsQuery = useQuery(
    "user-settings",
    getSettings,
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      onSuccess: (response) => {
        try {
          const parsed = JSON.parse(response.data.settings);
          if (!isUserSettings(parsed)) {
            loadUserSettingsFromLs();
            return;
          }
          forceUserSettings(parsed);
        } catch (error) {
          loadUserSettingsFromLs();
        }
      },
      onError: (err) => {
        Swal.fire({
          title: "Haku epäonnistui",
          text: "Käyttäjäasetusten haku epäonnistui, yritetäänkö uudestaan?",
          confirmButtonText: "Kyllä",
          denyButtonText: "Ei",
        }).then((choice) => {
          if (choice.isConfirmed) {
            window.location.reload();
          }
        });
      },
    }
  );

  const joinMutation = useMutation(postJoinList, {
    onSuccess: () => {
      listQuery.refetch();
      ownListOrderQuery.refetch();
    },
  });

  const leaveMutation = useMutation(postLeavelList, {
    onSuccess: () => {
      listQuery.refetch();
      ownListOrderQuery.refetch();
    },
  });

  const saveSettingsMutation = useMutation(
    async () => {
      const settings = JSON.stringify(userSettings);
      applySettings(settings);
    },
    {
      onError: () => {
        Swal.fire({
          title: "Tallennus palvelimelle epäonnistui",
          icon: "error",
          text: "Koita ladata sovellus uudestaan",
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      },
    }
  );

  const startSaveSettings = () => {
    if (saveSettingsTimerRef.current) {
      clearTimeout(saveSettingsTimerRef.current);
    }
    saveSettingsTimerRef.current = setTimeout(() => {
      saveSettingsMutation.mutate();
    }, 3000);
  };

  const removeLastListOpen = () => {
    lsRemove("last-open");
  };

  useCheckLastVisit(removeLastListOpen, 5 * 60 * 1000);

  useEffect(() => {
    const ownOn = lsGet("own-by-default");
    if (ownOn) {
      searchparams.set("own", "on");
      setSearchParams(searchparams);
    }
  }, []);

  const handleFilterFormSubmit = (
    event: ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = parseFormData(event);
    const queryPath = `/listat${createSearchQueryFromPairs(
      data
    )}`;
    removeLastListOpen();
    setShowOwnOrder(false);
    return navigate(queryPath);
  };

  const handleJoinOrLeaveButton = (id: number) => {
    const listinOwnOrderQuery = _find(ownListOrderQuery.data, {
      id: id,
    });
    const listInListQuery = _find(
      listQuery.data,
      (list) => list.id === id
    );

    const list = listinOwnOrderQuery || listInListQuery;
    if (list?.user_in_list) {
      Swal.fire({
        title: "Haluatko varmasti poistua listalta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Poistu",
        cancelButtonText: "Peruuta",
      }).then((result) => {
        if (result.isConfirmed) {
          leaveMutation.mutate(id);
        }
      });
    }
    if (!list?.user_in_list) {
      joinMutation.mutate(id);
    }
  };

  const filterItems = (lists: listIndexResponse[]) => {
    const hiddenOn = searchparams.get("hidden") === "on";
    const ownOn = searchparams.get("own") === "on";
    const hiddenLists = getHiddenLists();

    return _filter(lists, (list) => {
      const showDueToHidden =
        hiddenOn || !hiddenLists.includes(list.id);
      const showDueToOwn = !ownOn || list.user_in_list;

      return showDueToHidden && showDueToOwn;
    });
  };

  const handleCompare = (id: number) => {
    const user = JSON.parse(
      localStorage.getItem("user") || ("" as string)
    );
    const ownId = user?.id;
    removeLastListOpen();
    navigate(
      `/vertaa?listId=${id}${
        ownId ? `&userIdFrom=${ownId}` : ""
      }`
    );
  };

  const handleHideButton = (id: number) => {
    Swal.fire({
      title: "Oletko varma?",
      text: "Haluatko varmasti piilottaa listan",
      confirmButtonText: "Kyllä",
      cancelButtonText: "Peruuta",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        toggleHiddenList(id);
      }
    });
  };

  const handleReorder = (order: number[]) => {
    const newOrder = order;
    if (!isArray(newOrder) || !loadedOrder || !loadedOrder.name)
      return;

    changeLoadedOrder({
      ...loadedOrder,
      order: newOrder as number[],
    });
    startSaveSettings();
  };

  const handleAddListToOwnListOrder = (id: number) => {
    if (!loadedOrder) {
      Swal.fire({
        title: "Virhe",
        text: "Järjestystä ei ole valittu",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }
    const isAlreadyInOrder =
      loadedOrder.order.indexOf(id) !== -1;

    if (isAlreadyInOrder) {
      Swal.fire({
        title: "Virhe",
        text: "Lista on jo järjestyksessä",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }
    changeLoadedOrder({
      ...loadedOrder,
      order: [...loadedOrder.order, id],
    });
    startSaveSettings();
  };

  const handleRemoveFromOwnListOrder = (id: number) => {
    if (!loadedOrder) {
      return;
    }

    const newOrder = loadedOrder.order.filter(
      (listId) => listId !== id
    );

    changeLoadedOrder({
      ...loadedOrder,
      order: newOrder,
    });
    startSaveSettings();
  };

  const handleDeleteOrder = (order: UserListOrder) => {
    removeUserListOrder(order.name);
    startSaveSettings();
  };

  const handleSetDefaultOrder = (order: UserListOrder) => {
    setOrderAsDefault(order.name);
    startSaveSettings();
  };

  const handleCloseOrderSettings = () => {
    setShowOrderSettings(false);
  };

  const handleAddOrder = () => {
    Swal.fire({
      title: "Lisää järjestys",
      text: "Anna järjestykselle nimi",
      input: "text",
      showCancelButton: true,
      confirmButtonText: "Lisää",
      cancelButtonText: "Peruuta",
    }).then((result) => {
      if (result.value.length <= 0) return;
      addUserListOrder(result.value);
      startSaveSettings();
    });
  };

  const dataOrdered = () => {
    const order = loadedOrder?.order || [];
    if (!order) return ownListOrderQuery.data || [];
    return (
      ownListOrderQuery.data?.sort((a, b) => {
        const aIndex = order.indexOf(a.id) || 0;
        const bIndex = order.indexOf(b.id) || 0;
        if (aIndex > bIndex) {
          return 1;
        } else if (aIndex < bIndex) {
          return -1;
        } else {
          return 0;
        }
      }) || []
    );
  };

  return (
    <>
      <Container>
        {keywordQuery.data && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="col-12 col-sm-11 col-md-7 col-lg-6 col-xl-5 col-xxl-4"
          >
            <PinnaListPageFilterForm
              filterSumbit={handleFilterFormSubmit}
              keywords={keywordQuery.data}
              queries={createPairsFromSearchQuery(
                location.search
              )}
              setShowOwnOrder={setShowOwnOrder}
            />
          </motion.div>
        )}
      </Container>
      <Container className="main-container py-2 extra-bottom-margin align-items-center">
        <div className="col-12 col-sm-10 col-md-9 col-lg-7 col-xxl-5">
          <div className="row w-100 m-0 p-0 justify-content-center mb-4">
            <div className="p-0 row justify-content-center w-100">
              <div
                className={clsx(
                  "text-center w-100 py-2 m-0 header-container shadow cursor-pointer row justify-content-center align-items-center",
                  {
                    "d-none": listQuery.isLoading,
                  }
                )}
              >
                <div
                  className="col-2 m-0 p-0 text-start color-dark-brown hover-highlight-color"
                  onClick={() => setShowOwnOrder(!showOwnOrder)}
                >
                  <motion.div
                    animate={
                      showOwnOrder
                        ? {
                            rotateZ: 0,
                          }
                        : {
                            rotateZ: 180,
                          }
                    }
                    style={{
                      width: "15px",
                    }}
                  >
                    <i className="bi bi-caret-up-fill"></i>
                  </motion.div>
                </div>
                <div
                  className="col-8 m-0 p-0"
                  onClick={() => setShowOwnOrder(!showOwnOrder)}
                >
                  <p className="m-0 p-0">
                    <strong>
                      Oma järjestys{" "}
                      {loadedOrder?.name.toLowerCase() !==
                        "oletus" &&
                        `(${loadedOrder?.name || "Ei valittu"})`}
                    </strong>
                  </p>
                </div>
                <div
                  className="col-2 m-0 p-0 text-end color-dark-brown hover-highlight-color"
                  onClick={() => {
                    setShowOrderSettings(true);
                  }}
                >
                  <i className="bi bi-gear-fill"></i>
                </div>
                <OrderSettings
                  userSettings={userSettings}
                  open={showOrderSettings}
                  close={handleCloseOrderSettings}
                  handleAddOrder={handleAddOrder}
                  handleDeleteOrder={handleDeleteOrder}
                  handleSetDefaultOrder={handleSetDefaultOrder}
                />
              </div>
              <AnimatePresence>
                {showOwnOrder && !listQuery.isLoading && (
                  <div
                    className={
                      "w-100 m-0 p-0 row justify-content-center align-items-center"
                    }
                  >
                    <Reorder.Group
                      className="w-100 m-0 p-0"
                      id="list-order"
                      onReorder={(order: number[]) => {
                        handleReorder(order);
                      }}
                      values={loadedOrder?.order || []}
                    >
                      {ownListOrderQuery.isLoading ? (
                        <div className="w-100 row justify-content-center">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        dataOrdered().map((list) => (
                          <ListHeaderReorderable
                            key={list.id}
                            index={list.id}
                            list={list}
                            isHidden={false}
                            onHide={handleHideButton}
                            onJoinOrLeave={
                              handleJoinOrLeaveButton
                            }
                            onCompare={handleCompare}
                            handleAddToOwnListOrder={
                              handleRemoveFromOwnListOrder
                            }
                          />
                        ))
                      )}
                    </Reorder.Group>
                  </div>
                )}
              </AnimatePresence>
            </div>
          </div>

          {listQuery.data && !listQuery.isLoading && (
            <div className="row w-100 m-0 p-0 justify-content-center gap-2">
              <div className="m-0 p-0">
                <p className="text-center d-flex justify-content-center w-100 py-2 m-0 header-container shadow">
                  <strong>Hakutulokset</strong>
                </p>
              </div>

              <div className="w-100 m-0 p-0 row gap-2">
                {_map(
                  filterItems(listQuery.data),
                  (list, index) => (
                    <PinnaListHeaderItem
                      key={index}
                      list={list}
                      isHidden={getHiddenLists().includes(
                        list.id
                      )}
                      onHide={handleHideButton}
                      onJoinOrLeave={handleJoinOrLeaveButton}
                      onCompare={handleCompare}
                      handleAddToOwnListOrder={
                        handleAddListToOwnListOrder
                      }
                    />
                  )
                )}
              </div>
            </div>
          )}
          {(!listQuery.data || listQuery.isFetching) && (
            <div className="row w-100 m-0 p-0 py-2 justify-content-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

interface ListHeaderReorderableProps {
  list: listIndexResponse;
  index: number;
  isHidden: boolean;
  onHide: any;
  onJoinOrLeave: any;
  onCompare: any;
  handleAddToOwnListOrder: any;
}

function ListHeaderReorderable(
  props: ListHeaderReorderableProps
) {
  const {
    list,
    isHidden,
    onHide,
    onJoinOrLeave,
    onCompare,
    handleAddToOwnListOrder,
  } = props;

  const dragControls = useDragControls();

  /** Needed for dragging to work */
  const reorderRef = useRef<HTMLElement | null>(null);

  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const touchHandler: React.TouchEventHandler<HTMLElement> = (
      e
    ) => {
      e.preventDefault();
    };

    const ReorderCurrent = reorderRef.current;

    if (ReorderCurrent) {
      ReorderCurrent.addEventListener(
        "touchstart",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        touchHandler,
        {
          passive: false,
        }
      );

      return () => {
        ReorderCurrent.removeEventListener(
          "touchstart",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          touchHandler,
          {
            passive: false,
          }
        );
      };
    }
    return;
  }, [reorderRef]);

  return (
    <Reorder.Item
      value={list.id}
      key={list.id}
      dragListener={false}
      dragControls={dragControls}
      className="ul-no-style w-100"
      onDragStart={() => {
        setIsDragging(true);
      }}
      onDragEnd={() => {
        setIsDragging(false);
      }}
    >
      <div
        className="row w-100 flex-nowrap m-0 p-0 align-items-center my-2"
        style={{
          pointerEvents: isDragging ? "none" : "auto",
          userSelect: isDragging ? "none" : "auto",
        }}
      >
        <div className="flex-fill m-0 p-0">
          <PinnaListHeaderItem
            list={list}
            isHidden={isHidden}
            onHide={onHide}
            canHide={false}
            isInOrder={true}
            handleAddToOwnListOrder={handleAddToOwnListOrder}
            onJoinOrLeave={onJoinOrLeave}
            onCompare={onCompare}
          />
        </div>
        <div
          onPointerDown={(e) => {
            dragControls.start(e);
          }}
          ref={reorderRef as any}
          className={clsx(
            "m-0 p-0 py-3 header-handle d-flex justify-content-center align-items-center",
            {
              "header-handle-own": list.user_in_list,
            }
          )}
        >
          <i className="text-white bi bi-grip-vertical"></i>
        </div>
      </div>
    </Reorder.Item>
  );
}

export default PinnaListsPage;
