import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { requestPasswordChangeNoAuth } from "../../Utils/apiUtils";

function ProfileChangePasswordRequest() {
  const [email, setEmail] = useState("");

  const applyKeyMutation = useMutation(
    () => requestPasswordChangeNoAuth(email || ""),
    {
      onSuccess: () => {
        Swal.fire({
          title: "Linkki vaihtoon lähetetty",
          text: "Saat pian sähköpostiisi linkin salasanan vaihtoon",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/login");
        });
      },
      onError: () => {
        Swal.fire({
          title: "Pyyntö epäonnistui",
          text: "Tarkistathan että sähköposti on oikein",
          icon: "error",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/login");
        });
      },
    }
  );

  const navigate = useNavigate();
  return (
    <div className="login-bg">
      <div className="m-0 p-0 position-fixed top-50 start-50 translate-middle w-100">
        <div className="row justify-content-center">
          <div className="shadow-md login-page-input-container bg-white col-10 col-sm-6 col-md-5 col-xl-3 text-center px-5 py-3">
            <h3 className="mb-3">Pyydä salasananvaihtolinkki</h3>
            <p className="text-start m-0 p-0">
              Anna sähköpostisi niin lähetämme sinulle salasanan
              vaihtolinkin
            </p>
            <input
              className="w-100 p-1 login-input my-2"
              type="email"
              placeholder="Sähköposti"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={() => {
                applyKeyMutation.mutate();
              }}
            >
              Pyydä uusi salasana
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileChangePasswordRequest;
