import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import HrefWrapper from "../../Components/HrefWrapper";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useLocalStorageValue from "../../Hooks/useLocalStorageValue";
import { ReactComponent as BirdIcon } from "../../Images/bird.svg";
import { listIndexResponse } from "../../Types/apiResponses";
import {
  fetchHasSeenSpeciesCount,
  fetchListUsersByList,
  getUser,
} from "../../Utils/apiUtils";
import PinnaListDetailsSearch from "./PinnaListDetailsSearch";
import PinnaListUserDetails from "./PinnaListUserDetails";
import PinnarListSpeciesDetails from "./PinnarListSpeciesDetails";
import "./styles.scss";

interface PinnaListHeaderItemProps {
  list: listIndexResponse;
  isHidden: boolean;
  onHide: (id: number) => void;
  onJoinOrLeave: (id: number) => void;
  onCompare: (id: number) => void;
  handleAddToOwnListOrder: (id: number) => void;
  canHide?: boolean;
  isInOrder?: boolean;
}

/**
 * Header item by default shows list basic info
 */
function PinnaListHeaderItem({
  list,
  isHidden = false,
  onHide,
  onJoinOrLeave,
  onCompare,
  handleAddToOwnListOrder,
  canHide = true,
  isInOrder = false,
}: PinnaListHeaderItemProps) {
  const [detailPageOpen, setDetailPageOpen] = useState<
    "species" | "users" | "closed"
  >("closed");

  const [searchFilter, setSearchFilter] = useState<string>("");
  const [lastOpen, setLastOpen, removeLastOpen] =
    useLocalStorageValue<string | null>("last-open", null);

  const selfRef = useRef<HTMLDivElement>(null);

  const listUserDetailQuery = useQuery(
    ["list-observation-details", list.id],
    () => fetchListUsersByList(list.id),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const listSpeciesDetailQuery = useQuery(
    ["list-species-details", list.id],
    () => fetchHasSeenSpeciesCount(list.id),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (lastOpen !== null) {
      const [page, id] = lastOpen.split("-");
      if (page === "species" && id === list.id.toString()) {
        setDetailPageOpen("species");
        listSpeciesDetailQuery.refetch();
      } else if (page === "users" && id === list.id.toString()) {
        setDetailPageOpen("users");
        listUserDetailQuery.refetch();
      }
    }
  }, [lastOpen]);

  /** Scroll to list when its opened and it has loaded */
  useEffect(() => {
    if (detailPageOpen !== "closed" && selfRef.current) {
      setTimeout(() => {
        selfRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 700);
    }
  }, [detailPageOpen, selfRef.current]);

  const getOwnObservationHref = () => {
    const userId = getUser().id;
    return `/havainnot?userId=${userId}&listId=${list.id}`;
  };

  const getCheckboxHref = () => {
    return `/rasti/${list.id}`;
  };

  const handleOpen = (page: "species" | "users" | "closed") => {
    if (page === detailPageOpen) {
      setDetailPageOpen("closed");
      removeLastOpen();
    } else {
      setDetailPageOpen(page);
      setLastOpen(`${page}-${list.id}`);
    }
    if (page === "species" && !listSpeciesDetailQuery.data) {
      listSpeciesDetailQuery.refetch();
    }
    if (page === "users" && !listUserDetailQuery.data) {
      listUserDetailQuery.refetch();
    }
  };

  const shouldLoad = () => {
    if (detailPageOpen === "species") {
      return listSpeciesDetailQuery.isFetching;
    }
    if (detailPageOpen === "users") {
      return listUserDetailQuery.isFetching;
    }
  };

  return (
    <div
      className={`row w-100 m-0 px-2 py-3 my-0 shadow align-items-center ${
        list.user_in_list
          ? "header-container-favourite"
          : "header-container"
      }`}
      ref={selfRef}
    >
      <div
        className="col-4 col-md-6 cursor-pointer m-0 p-0 align-items-center"
        onClick={() => handleOpen("users")}
      >
        <b>{list.name}</b>
      </div>
      <div className="col-8 col-md-6 d-flex m-0 p-0 justify-content-end">
        <div className="row w-100 gap-1 d-flex flex-wrap m-0 p-0 justify-content-end">
          <div className="col-1 p-0 m-0 text-center">
            {list.user_in_list === true && (
              <HrefWrapper href={getOwnObservationHref()}>
                <i
                  title="Omat havainnot"
                  className={
                    "bi bi-binoculars-fill cursor-pointer icon-hover"
                  }
                ></i>
              </HrefWrapper>
            )}
          </div>
          <div className="col-1 p-0 m-0 text-center">
            {list.user_in_list && (
              <HrefWrapper href={getCheckboxHref()}>
                <i
                  title="Rastilista"
                  className={
                    "bi bi-pencil-fill cursor-pointer icon-hover"
                  }
                ></i>
              </HrefWrapper>
            )}
          </div>
          <div
            className="col-3 col-md-2 p-0 m-0 text-center person-count"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <i
              title="Avaa käyttäjien pinnat"
              onClick={() => {
                handleOpen("users");
              }}
              className={"bi bi-person-fill icon-hover"}
            >
              <b className="pressable d-inline-box">
                {list.list_user_count}
              </b>
            </i>
          </div>
          <div className="col-1 p-0 m-0 text-center">
            <BirdIcon
              className="icon-hover-svg"
              style={{ width: "20px" }}
              title="Avaa lajilista"
              onClick={() => {
                handleOpen("species");
              }}
            />
          </div>
          <div className="col-1 p-0 m-0 text-center">
            <i
              title={"Vertaile"}
              onClick={() => {
                onCompare(list.id);
              }}
              className={
                "bi bi-plus-slash-minus cursor-pointer icon-hover"
              }
            ></i>
          </div>
          <div className="col-1 p-0 m-0 text-center">
            <i
              title={
                list.user_in_list
                  ? "Poistu listasta"
                  : "Liity listaan"
              }
              onClick={() => {
                onJoinOrLeave(list.id);
              }}
              className={`bi cursor-pointer icon-hover ${
                list.user_in_list
                  ? "bi-door-open-fill"
                  : "bi-door-closed-fill"
              }`}
              style={
                list.user_in_list ? { color: "#ffbe00" } : {}
              }
            ></i>
          </div>
          <div
            className={clsx("col-1 p-0 m-0 text-center", {
              "d-none": !canHide,
            })}
          >
            <i
              title={
                isHidden
                  ? "Poista listan piilotus"
                  : "Piilota lista"
              }
              onClick={() => {
                onHide(list.id);
              }}
              className={`bi cursor-pointer icon-hover
                               ${
                                 isHidden
                                   ? "bi-eye-fill"
                                   : "bi-eye-slash-fill"
                               }
                        `}
              style={isHidden ? { color: "#ffbe00" } : {}}
            ></i>
          </div>
          <div
            className="col-1 p-0 m-0 text-center"
            onClick={(e) => {
              handleAddToOwnListOrder(list.id);
            }}
          >
            <i
              title={`${
                isInOrder
                  ? "Poista omasta järjestyksestä"
                  : "Lisää omaan järjestykseen"
              }`}
              className={`bi cursor-pointer icon-hover ${
                isInOrder ? "bi-folder-minus" : "bi-folder-plus"
              }`}
            ></i>
          </div>
        </div>
      </div>
      {shouldLoad() && (
        <div className="row w-100 m-0 p-0 py-1 justify-content-center">
          <hr className="w-100 my-2" />
          <LoadingSpinner />
        </div>
      )}
      {detailPageOpen === "users" &&
        !shouldLoad() &&
        listUserDetailQuery.data && (
          <div className="row w-100 m-0 p-0 py-1 justify-content-start">
            {listUserDetailQuery.data.user_scores.length > 0 ? (
              <>
                <hr className="w-100 my-2" />
                <div className="w-100 row justify-content-center align-items-center m-0 p-0 my-2">
                  <div className="col-12 col-md-8 m-0 p-0 px-1">
                    <PinnaListDetailsSearch
                      text="Hae nimeä"
                      setSearchFilter={setSearchFilter}
                      className="w-100 p-1"
                    />
                  </div>
                  <div className="col-12 col-md-4 m-0 p-0 px-1">
                    <div className="w-100 fresh-button text-center m-0 p-0 py-1 cursor-pointer">
                      <HrefWrapper
                        href={`/tuoreimmat/${list.id}`}
                      >
                        <p className="p-0 m-0">Tuoreimmat</p>
                      </HrefWrapper>
                    </div>
                  </div>
                </div>

                <PinnaListUserDetails
                  filter={searchFilter}
                  listId={list.id}
                  listUserDetails={listUserDetailQuery.data}
                />
              </>
            ) : (
              <p className="text-center m-0 p-0 my-1">
                <b>Kukaan ei ole listalla</b>
              </p>
            )}
          </div>
        )}
      {detailPageOpen === "species" &&
        !shouldLoad() &&
        listSpeciesDetailQuery.data && (
          <div className="row w-100 m-0 p-0 py-1 justify-content-center">
            {listSpeciesDetailQuery.data.length > 0 ? (
              <>
                <hr className="w-100 my-2" />
                <PinnaListDetailsSearch
                  className="w-100 p-1"
                  text="Hae lajia"
                  setSearchFilter={setSearchFilter}
                />
                <PinnarListSpeciesDetails
                  filter={searchFilter}
                  listSpeciesDetails={
                    listSpeciesDetailQuery.data
                  }
                  listId={list.id}
                />
              </>
            ) : (
              <p className="text-center m-0 p-0 my-1">
                <b>Listalla ei ole lajeja</b>
              </p>
            )}
          </div>
        )}
    </div>
  );
}

export default PinnaListHeaderItem;
