import * as LocalStorage from "local-storage";
import { useState } from "react";

export default function useLocalStorageValue<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void, () => void] {
  const [value, setValue] = useState(() => {
    try {
      const item = LocalStorage.get(key) as T;
      return item !== null ? item : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValueAndLocalStorage = (newValue: T) => {
    setValue(newValue);
    LocalStorage.set(key, newValue);
  };

  const removeValueAndLocalStorage = () => {
    setValue(initialValue);
    LocalStorage.remove(key);
  };

  return [
    value,
    setValueAndLocalStorage,
    removeValueAndLocalStorage,
  ];
}
