import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import ProfileChangePasswordApply from "./Pages/ChangePassword/ProfileChangePasswordApply";
import ProfileChangePasswordRequest from "./Pages/ChangePassword/ProfileChangePasswordRequest";
import ComparePage from "./Pages/ComparePage/ComparePage";
import Dashboard from "./Pages/Dashboard/Dashboard";
import LatestOnListPage from "./Pages/LatestOnListPage/LatestOnListPage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import NotFoundPage from "./Pages/NotFoundPage";
import CheckboxEditorPage from "./Pages/ObservationEditorPage/CheckboxEditorPage";
import ObservationEditorPage from "./Pages/ObservationEditorPage/ObservationEditorPage";
import ObservationSinglePage from "./Pages/ObservationsPage/ObservationSinglePage";
import ObservationsPage from "./Pages/ObservationsPage/ObservationsPage";
import ObservationsSeenPage from "./Pages/ObservationsPage/ObservationsSeenPage";
import PinnaListsPage from "./Pages/PinnaListsPage/PinnaListsPage";
import ProfileChangePage from "./Pages/ProfileChangePage";
import ProfilePage from "./Pages/ProfilePage";
import "./Styles/FInputs.scss";
import "./Styles/index.scss";
import { checkToken, initAxios } from "./Utils/apiUtils";

/**Initalize axios headers at root*/
initAxios();

/**ReactQuery queryclient*/
const queryClient = new QueryClient();

/**
 * Check user token and redirect if not valid.
 * loader will hang untill check is complete
 */
const rootLoader = async () => {
  const token = await checkToken();

  if (!token) {
    let redirectUrl = "/login";
    const currentPath = window.location.pathname;
    const currentQueryString = window.location.search;
    if (currentPath !== "/" && currentPath !== "/login") {
      const fullPath = encodeURIComponent(
        currentPath + currentQueryString
      );
      redirectUrl += `?redirect=${fullPath}`;
    }
    return redirect(redirectUrl);
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    loader: rootLoader,
    children: [
      {
        path: "listat",
        index: true,
        element: <PinnaListsPage />,
      },
      {
        path: "havainto",
        element: <ObservationEditorPage />,
      },
      {
        path: "havainnot",
        element: <ObservationsPage />,
      },
      {
        path: "havainnot/:id",
        element: <ObservationSinglePage />,
      },
      {
        path: "havainneet",
        element: <ObservationsSeenPage />,
      },
      {
        path: "vertaa",
        element: <ComparePage />,
      },
      {
        path: "tuoreimmat/:id",
        element: <LatestOnListPage />,
      },
      {
        path: "rasti/:id",
        element: <CheckboxEditorPage />,
      },
      {
        path: "profiili",
        element: <ProfilePage />,
      },
      {
        path: "muutos/:key",
        element: <ProfileChangePage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/vaihda-salasana",
    element: <ProfileChangePasswordRequest />,
  },
  {
    path: "/vaihda-salasana/:key",
    element: <ProfileChangePasswordApply />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
