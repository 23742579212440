import { format } from "date-fns";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import useLocalStorageValue from "../Hooks/useLocalStorageValue";
import { UserType } from "../Types/apiResponses";
import { requestUserInfoChange } from "../Utils/apiUtils";

function ProfilePage() {
  const [user] = useLocalStorageValue<UserType | null>(
    "user",
    null
  );

  const requestChangeMutation = useMutation(
    (type: "password" | "email") => requestUserInfoChange(type),
    {
      //Redirect to /profiili after success
      onSuccess: () => {
        Swal.fire({
          icon: "info",
          title: "Muutospyyntö lähetetty",
          text: "Lähetimme muutoslinkin sähköpostiisi, muista tarkistaa roskapostilaatikko",
          confirmButtonText: "Ok",
        });
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          title: "Virhe",
          text: "Jokin meni pieleen yrittäessämme käsitellä pyyntöäsi",
        });
      },
    }
  );

  if (!user) {
    return (
      <div className="w-100 p-0 m-0 pt-5 text-center ">
        <h5>
          Näyttäisi ettet ole kirjautunut sisään. Koita kirjautua
          uudestaan.
        </h5>
        <b>
          <p>Sovellus ei tule toimimaan ilman kirjautumista</p>
        </b>
      </div>
    );
  }

  return (
    <div className="container-fluid m-0 p-0">
      <div className="row w-100 m-0 p-0 justify-content-center main-container">
        <div className="col-12 col-sm-11 col-md-5 col-xxl-3 py-4">
          <div className="w-100 m-0 p-0 border-general bg-white profile-container">
            <div className="row w-100 m-0 p-0 gap-1">
              <div className="col-12 mt-3">
                <h4 className="text-center">Profiili</h4>
                <hr />
              </div>
              <div className="col-12 ps-4">
                Nimi:
                <b>
                  {` ${user.firstname} ${user.lastname}` ||
                    "Ei ole"}{" "}
                </b>
              </div>
              <div className="col-12 ps-4">
                Käyttäjänimi:
                <b>{` ${user.username}` || "Ei ole"}</b>
              </div>
              <div className="col-12 ps-4">
                Liittynyt:
                <b>
                  {` ${format(
                    new Date(user.created),
                    "dd.MM.yyyy"
                  )}` || "Ei ole"}
                </b>
              </div>
              <div className="col-12 ps-4">
                Sähköposti:
                <b>{` ${user.email}` || "Ei ole"}</b>
              </div>
              <div className="col-12 mt-3">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    requestChangeMutation.mutate("password");
                  }}
                  className="w-100 p-1 "
                >
                  Vaihda salasana
                </button>
              </div>
              <div className="col-12 mb-3">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    requestChangeMutation.mutate("email");
                  }}
                  className="w-100 p-1"
                >
                  Vaihda sähköposti
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
