interface PinnaListDetailsSearchProps {
  setSearchFilter: (filter: string) => void;
  text: string;
  className: string;
}

function PinnaListDetailsSearch({
  setSearchFilter,
  text,
  className,
}: PinnaListDetailsSearchProps) {
  return (
    <div className="m-0 p-0 my-3">
      <input
        type="text"
        className={className}
        placeholder={text}
        onChange={(e) => setSearchFilter(e.target.value)}
      />
    </div>
  );
}

export default PinnaListDetailsSearch;
