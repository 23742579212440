import { useParams } from "react-router-dom";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useCheckBoxUtility from "../../Hooks/useCheckBoxUtility";
import useLocalStorageValue from "../../Hooks/useLocalStorageValue";

import { UserType } from "../../Types/apiResponses";

import { useQuery } from "react-query";
import { getListTaxons } from "../../Utils/apiUtils";
import CheckBoxEditorList from "./CheckBoxEditorList";
import CheckboxEditorInputs from "./CheckboxEditorInputs";

export default function CheckboxEditorPage() {
  const [userData] = useLocalStorageValue<UserType>("user", {
    id: 0,
    username: "",
    firstname: "",
    lastname: "",
    state: "",
    email: "",
    created: "",
  });
  const { id } = useParams();

  const {
    handleSearchParamChange,
    utilityReady,
    getKeyWords,
    getPossibleContinents,
    getPossibleCountries,
    getPossibleAssociations,
    getPossibleMunicipalities,
    getListData,
    lockedSearchParams,
    getTaxons,
    deleteObs,
    createObs,
    redirectToEditPage,
  } = useCheckBoxUtility(
    parseInt(id || ""),
    userData.id === 0 ? undefined : userData.id
  );

  const list = getListData();
  const listTaxons = getTaxons();

  const seenOnListTaxonsQuery = useQuery(
    ["listTaxons", id],
    () => getListTaxons(parseInt(id || "")),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );

  if (!utilityReady() || !id) {
    return (
      <div className="w-100 row justify-content-center m-0 p-0 mt-5 text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {list && (
        <CheckboxEditorInputs
          handleInputChange={handleSearchParamChange}
          keywords={getKeyWords()}
          continents={getPossibleContinents()}
          countries={getPossibleCountries()}
          associations={getPossibleAssociations()}
          municipalities={getPossibleMunicipalities()}
          list={list}
          lockedFields={lockedSearchParams}
        />
      )}
      {listTaxons.length > 0 && (
        <CheckBoxEditorList
          taxons={listTaxons}
          list={list}
          createObs={createObs}
          deleteObs={deleteObs}
          redirectToEdit={redirectToEditPage}
          seenTaxons={seenOnListTaxonsQuery.data?.data || []}
        />
      )}
    </>
  );
}
