import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Swal from "sweetalert2";
import logo from "../Images/logo.svg";
import "../Styles/Commons.scss";
import { fetchMotd } from "../Utils/apiUtils";

function NewNavBar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const [screenSize, setScreenSize] = useState(
    window.innerWidth
  );

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () =>
      window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavCollapse = () =>
    setIsNavCollapsed(!isNavCollapsed);

  const motdQuery = useQuery("motd", fetchMotd, {
    refetchOnWindowFocus: false,
  });
  const { motd } = motdQuery.data?.data || {};

  const openMotd = () => {
    Swal.fire({
      title: "Pinnari viimeisin viesti",
      html: `<div class="text-start mx-3">${motd}</div>`,
      icon: "info",
      width: screenSize > 992 ? "60%" : "100%",
      confirmButtonText: "OK",
    });
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-main py-3 px-5 clip-odd"
      style={{ marginBottom: "-80px" }}
    >
      <div className="container-fluid">
        {/* Logo */}
        <a
          className="navbar-brand text-center"
          href="https://bongariliitto.fi"
        >
          <h5 className="nav-link-override m-0 p-0">
            <img
              className=""
              width={60}
              height={60}
              src={logo}
              alt="Bongariliitto logo"
            />
            Bongariliitto
          </h5>
        </a>

        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleNavCollapse}
          aria-expanded={!isNavCollapsed}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Collapsible content */}
        <div
          className={`${
            isNavCollapsed ? "collapse" : ""
          } navbar-collapse justify-content-between py-3 px-2`}
          id="navbarNav"
        >
          {/* Centered links on large screens */}
          <ul className="navbar-nav mx-auto">
            <li className="nav-item mx-3 ">
              <a
                className="nav-link nav-link-override "
                href="https://bongariliitto.fi/index.php/pinnari-ohjeet/"
              >
                <p className="font-weight-bold m-0 p-0">
                  Ohjeet
                </p>
              </a>
            </li>
            <li className="nav-item mx-3">
              <a
                className="nav-link nav-link-override"
                href="https://bongariliitto.fi/index.php/lajilistat/"
              >
                <p className="font-weight-bold m-0 p-0">
                  Taksonomia
                </p>
              </a>
            </li>
            <li className="nav-item mx-3">
              <a
                className="nav-link nav-link-override"
                href="https://bongariliitto.fi/index.php/parhaat-vuodet/"
              >
                <p className="font-weight-bold m-0 p-0">
                  Vuodenpinnaennätykset
                </p>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav" id="navbar-icons">
            <li className="nav-item mx-3">
              <a
                className="nav-link nav-link-override"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openMotd();
                }}
              >
                {screenSize > 992 ? (
                  <i
                    style={{ fontSize: "25px" }}
                    className="bi bi-info-circle-fill"
                  ></i>
                ) : (
                  <p className="m-0 p-0">Viimeisin viesti</p>
                )}
              </a>
            </li>
            <li className="nav-item mx-3">
              <a
                className="nav-link nav-link nav-link-override"
                href="/profiili"
              >
                {screenSize > 992 ? (
                  <i
                    style={{ fontSize: "25px" }}
                    className="bi bi-person-circle"
                  ></i>
                ) : (
                  <p className="m-0 p-0">Profiili</p>
                )}
              </a>
            </li>
            <li className="nav-item mx-3">
              <a
                className="nav-link nav-link nav-link-override"
                href="#"
                onClick={() => {
                  localStorage.removeItem("token");
                }}
              >
                {screenSize > 992 ? (
                  <i
                    style={{ fontSize: "25px" }}
                    className="bi bi-door-open-fill"
                  ></i>
                ) : (
                  <p className="m-0 p-0">Kirjaudu ulos</p>
                )}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NewNavBar;
