import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { applyPasswordChangeNoAuth } from "../../Utils/apiUtils";

function ProfileChangePasswordApply() {
  const { key } = useParams();

  const applyKeyMutation = useMutation(
    () => applyPasswordChangeNoAuth(key || ""),
    {
      onSuccess: () => {
        Swal.fire({
          title: "Salasanan vaihto onnistui!",
          text: "Saat uuden salasanan sähköpostiisi piakkoin.",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/login");
        });
      },
      onError: () => {
        Swal.fire({
          title: "Salasanan vaihto epäonnistui",
          text: "Pyyntöä ei löytynyt tai se on vanhentunut",
          icon: "error",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/login");
        });
      },
    }
  );

  const navigate = useNavigate();
  return (
    <div className="login-bg">
      <div className="m-0 p-0 position-fixed top-50 start-50 translate-middle w-100">
        <div className="row justify-content-center">
          <div className="shadow-md login-page-input-container bg-white col-10 col-sm-6 col-md-5 col-xl-3 text-center px-5 py-3">
            <h3>Vaihda salasana</h3>
            <p>
              Kun painat alla olevaa painiketta niin salasanasi
              uusitaan
            </p>
            <button
              onClick={() => {
                applyKeyMutation.mutate();
              }}
            >
              Lähetä uusi salasana
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileChangePasswordApply;
