interface CheckBoxPaginationProps {
  rowCount: number;
  rowsPerPage: number;
  page: number;
  showPagesCount: number;
  changePage: (page: number) => void;
}

/**
 * Component for showing pagination navigation for checkbox component
 * returns left arrow < right arrow > first, last and numbers for next and last showPagesCount
 * so something like < first 3 4 5 <bold>6</bold> 7 8 9 last >
 * the onclick will be implemented later
 */
function CheckBoxPagination({
  rowCount,
  rowsPerPage,
  page,
  showPagesCount,
  changePage,
}: CheckBoxPaginationProps) {
  const maxPage = () => {
    return Math.ceil(rowCount / rowsPerPage);
  };
  const minPage = () => {
    return 1;
  };

  const pageNumberElements = () => {
    const elements = [];
    const max = maxPage();
    const min = minPage();
    const current = page;
    const show = showPagesCount;
    const start = current - show;
    const end = current + show;
    const startPage = start < min ? min : start;
    const endPage = end > max ? max : end;

    for (let index = startPage; index <= endPage; index++) {
      elements.push(
        <li className="page-item" key={index}>
          <p
            className="page-link cursor-pointer"
            style={{ color: "#a07702" }}
            onClick={() => changePage(index - 1)}
          >
            {index === page + 1 ? <b>{index}</b> : index}
          </p>
        </li>
      );
    }
    return elements;
  };

  return (
    <div className="w-100 d-flex justify-content-center m-0 p-0">
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className="page-item">
            <p
              className="page-link cursor-pointer"
              aria-label="Previous"
              style={{ color: "#a07702" }}
              onClick={() => {
                if (page > minPage() - 1) {
                  changePage(page - 1);
                }
              }}
            >
              <span aria-hidden="true">&laquo;</span>
            </p>
          </li>

          {pageNumberElements()}
          <li className="page-item">
            <p
              className="page-link cursor-pointer"
              style={{ color: "#a07702" }}
              aria-label="Next"
              onClick={() => {
                if (page < maxPage() - 1) {
                  changePage(page + 1);
                }
              }}
            >
              <span aria-hidden="true">&raquo;</span>
            </p>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default CheckBoxPagination;
