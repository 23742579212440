import { createPairsFromSearchQuery } from "../../Utils/generalUtils";

export const createObservationBody = (
  searchParams: string,
  species: string,
  additional_info: string,
  date: string,
  keywords: string
) => {
  const requestFields = createPairsFromSearchQuery(searchParams);
  const { area, continent, country, association, municipality } =
    requestFields;

  const requestBody: any = {};

  requestBody.keywords = keywords;
  requestBody.area = area;
  requestBody.continent = continent;
  requestBody.country = country;
  requestBody.association = association;
  requestBody.municipality = municipality;
  requestBody.additional_info = additional_info;
  requestBody.date = date;
  requestBody.chosen_species_id = species;

  return requestBody;
};
