import { useEffect, useState } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import { createObservationBody } from "../Pages/ObservationEditorPage/observationUtils";
import {
  AssociationIndexResponse,
  ContinentIndexResponse,
  CountryIndexResponse,
  ExtendedTaxonOptionResponse,
  FilteredObservationResponse,
  FilteredObservationsResponse,
  KeywordIndexResponse,
  ListResponse,
  MunicipalityIndexResponse,
} from "../Types/apiResponses";
import {
  deleteObservation,
  fetchFilteredObservations,
  postObservation,
} from "../Utils/apiUtils";
import useOptions from "./useOptions";

type IIOCSpeciesLanguage =
  | "ioc_finnish"
  | "ioc_english"
  | "ioc_scientific";

const LOCKED_FROM_LIST = [
  "date_from",
  "date_to",
  "interval_monthday_from",
  "interval_monthday_to",
  "continent_id",
  "country_id",
  "association_id",
  "municipality_id",
  "is_wp",
  "keyword",
];

type ListTaxon = Partial<FilteredObservationResponse> & {
  isFromObs?: boolean;
};

export default function useCheckBoxUtility(
  listId?: number,
  userId?: number
) {
  const { getCollectionItems, isReady } = useOptions();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [language, setLanguage] =
    useState<IIOCSpeciesLanguage>("ioc_finnish");
  const queryClient = useQueryClient();

  /** Page pagination for checkbox list */
  const [paginationPage, setPaginationPage] =
    useState<number>(0);

  /** Search parameters which are list defined and can't be changed no matter what */
  const [lockedSearchParams, setLockedSearchParams] = useState<
    Array<string>
  >([]);

  const location = useLocation();

  /** ----------------[Queries/Mutations]----------------------- */

  /** Will never fetch without listId and userId so they can be casted */
  const observationsQuery =
    useQuery<FilteredObservationsResponse>(
      ["user-checkbox-obs", listId, userId],
      () =>
        fetchFilteredObservations(
          listId as unknown as string,
          userId as unknown as string,
          null,
          true
        ),
      {
        enabled: !!listId || !!userId,
        refetchOnWindowFocus: false,
      }
    );

  /** delete observation by id mutation */
  const deleteObservationMutation = useMutation(
    (id: number) => deleteObservation(id),
    {
      onSuccess: () => {
        observationsQuery.refetch();
      },
      onMutate: async (id: number) => {
        /** delete obs from query */
        await queryClient.cancelQueries([
          "user-checkbox-obs",
          listId,
          userId,
        ]);
        const previousObs =
          queryClient.getQueryData<FilteredObservationsResponse>(
            ["user-checkbox-obs", listId, userId]
          );
        if (previousObs) {
          queryClient.setQueryData<FilteredObservationsResponse>(
            ["user-checkbox-obs", listId, userId],
            {
              ...previousObs,
              observations: previousObs.observations.filter(
                (obs) => obs.id !== id
              ),
            }
          );
        }
        return { previousObs };
      },
    }
  );

  const createObservationMutation = useMutation(
    postObservation,
    {
      onSuccess: () => {
        observationsQuery.refetch();
      },
      onError: () => {
        /** revert on ok */
        Swal.fire({
          icon: "error",
          title: "Virhe",
          text: "Virhe havaintoa tallentaessa",
        });
      },
      onMutate: async (body: any) => {
        /** Have to fake response to get proper fast ui */
        /** observation to be */
        const obs = body.single;
        /** Make it likely we wont hit same id */
        const randomId = -Math.floor(Math.random() * 1000000);
        /** add observation to query */
        await queryClient.cancelQueries([
          "user-checkbox-obs",
          listId,
          userId,
        ]);
        const previousObs =
          queryClient.getQueryData<FilteredObservationsResponse>(
            ["user-checkbox-obs", listId, userId]
          );
        if (previousObs) {
          queryClient.setQueryData<FilteredObservationsResponse>(
            ["user-checkbox-obs", listId, userId],
            {
              ...previousObs,
              observations: [
                ...previousObs.observations,
                {
                  ...obs,
                  species_id: obs.id,
                  id: randomId,
                },
              ],
            }
          );
        }
      },
    }
  );

  /**----------------------[Effects]------------------------------------ */

  /** Set values from list data */
  useEffect(() => {
    if (observationsQuery.data?.list) {
      if (observationsQuery.data?.list !== null) {
        const lockedParams = LOCKED_FROM_LIST.filter(
          (param) =>
            observationsQuery.data?.list &&
            observationsQuery.data?.list[
              param as keyof ListResponse
            ]
        );
        setLockedSearchParams(lockedParams);
      }
      const newSearchParams = new URLSearchParams();
      const list = observationsQuery.data?.list;
      let area = "3";
      if (list.is_wp === 1) {
        area = "1";
      }
      if (list.is_wp === 0) {
        area = "2";
      }
      newSearchParams.set("area", area);
      if (list.continent_id) {
        newSearchParams.set(
          "continent",
          list.continent_id.toString()
        );
      }
      if (list.country_id) {
        newSearchParams.set(
          "country",
          list.country_id.toString()
        );
      }
      if (list.association_id) {
        newSearchParams.set(
          "association",
          list.association_id.toString()
        );
      }
      if (list.municipality_id) {
        newSearchParams.set(
          "municipality",
          list.municipality_id.toString()
        );
      }
      if (list.keyword) {
        newSearchParams.set("keywords", list.keyword.toString());
      }
      /** Default settings for search */
      newSearchParams.set("limit", "1000");
      newSearchParams.set("language", "ioc_finnish");
      setSearchParams(newSearchParams, {
        replace: true,
      });
    }
  }, [observationsQuery.data?.list, listId]);

  useEffect(() => {
    if (listId) {
      /** clear all search params */
      observationsQuery.refetch();
    }
  }, [listId]);

  /**------------------------[Utility functions]---------------------------------- */

  const getListData = () => {
    return observationsQuery.data?.list;
  };

  const getObservations = () => {
    return observationsQuery.data?.observations;
  };

  const handleSearchParamChange = (
    name: string,
    value: string | undefined
  ) => {
    if (lockedSearchParams.includes(name)) {
      return;
    }
    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }
    setSearchParams(searchParams, {
      replace: true,
    });
  };

  const utilityReady = () => {
    return (
      observationsQuery.data?.list !== undefined &&
      observationsQuery.data.observations &&
      isReady
    );
  };

  const getKeyWords = () => {
    if (!isReady) return [];
    return getCollectionItems<KeywordIndexResponse>(
      "keywords",
      () => true
    );
  };

  const getPossibleContinents = () => {
    if (!isReady) return [];
    const area = searchParams.get("area");

    const continentsFilter = (
      continent: ContinentIndexResponse
    ) => {
      if (!area || area === "3") {
        return true; // Return all continents if area is not defined or area equals "3"
      }

      if (area === "1") {
        return continent.wp === 1; // Return continents where wp is 1, if area equals "1"
      }

      if (area === "2") {
        return continent.nowp === 1; // Return continents where nowp is 1, if area equals "2"
      }

      return false;
    };

    return getCollectionItems<ContinentIndexResponse>(
      "continents",
      continentsFilter
    );
  };

  const getPossibleCountries = () => {
    if (!isReady) return [];

    const continent = searchParams.get("continent");
    const area = searchParams.get("area");

    const countriesFilter = (country: CountryIndexResponse) => {
      if (
        continent &&
        country.continent_id.toString() !== continent
      ) {
        return false; // Exclude countries that do not belong to the specified continent
      }

      if (area) {
        if (area === "1" && country.wp !== 1) {
          return false; // Exclude countries where wp is not 1, if area equals "1"
        }

        if (area === "2" && country.nowp !== 1) {
          return false; // Exclude countries where nowp is not 1, if area equals "2"
        }

        if (area === "3") {
          return true; // Include all countries if area equals "3"
        }
      }

      return true;
    };

    return getCollectionItems<CountryIndexResponse>(
      "countries",
      countriesFilter
    );
  };

  const getPossibleAssociations = () => {
    if (!isReady) return [];
    const country = searchParams.get("country");
    if (country) {
      return getCollectionItems<AssociationIndexResponse>(
        "associations",
        (association: AssociationIndexResponse) => {
          return association.country_id === parseInt(country);
        }
      );
    } else {
      return getCollectionItems<AssociationIndexResponse>(
        "associations",
        () => true
      );
    }
  };

  const getPossibleMunicipalities = () => {
    if (!isReady) return [];
    const association = searchParams.get("association");
    if (association) {
      return getCollectionItems<MunicipalityIndexResponse>(
        "municipalities",
        (municipality: MunicipalityIndexResponse) => {
          return municipality.association_id ===
            parseInt(association)
            ? true
            : false;
        }
      );
    } else {
      return getCollectionItems<MunicipalityIndexResponse>(
        "municipalities",
        () => true
      );
    }
  };

  const getTaxons = () => {
    if (!isReady) return [];

    const fin = searchParams.get("country") === "162" ? 1 : 0;
    let wp: number | undefined = undefined;

    if (searchParams.get("area") === "1") {
      wp = 1;
    } else if (searchParams.get("area") === "2") {
      wp = 0;
    }

    const ownObservations = observationsQuery.data?.observations;
    if (!ownObservations) return [];

    const compareFunction = (
      taxon: ExtendedTaxonOptionResponse
    ) => {
      if (fin === 1 && taxon.fin !== 1) {
        return false;
      }

      if (wp === 1 && taxon.wp !== 1) {
        return false;
      }

      return true;
    };

    const taxons =
      getCollectionItems<ExtendedTaxonOptionResponse>(
        "taxons",
        compareFunction
      );

    const taxonsWithoutSeen = taxons.filter((taxon) => {
      const found = ownObservations.find((obs) => {
        return obs.species_id === taxon.id;
      });
      return found === undefined;
    });

    (ownObservations as ListTaxon[]).forEach((obs) => {
      obs.isFromObs = true;
    });

    const taxonsWithSeen = (
      taxonsWithoutSeen as ListTaxon[]
    ).concat(ownObservations);

    taxonsWithSeen.sort((a, b) => {
      if (
        (a.ioc_systord === 0 || b.ioc_systord === 0) &&
        a.ioc_systord !== b.ioc_systord
      ) {
        return a.ioc_systord === 0 ? -1 : 1;
      }
      if (!a.ioc_systord && !b.ioc_systord) return 0;
      if (!a.ioc_systord) return 1;
      if (!b.ioc_systord) return -1;
      return a.ioc_systord - b.ioc_systord;
    });

    return taxonsWithSeen;
  };

  const deleteObs = (id: number) => {
    Swal.fire({
      title: "Haluatko varmasti poistaa havainnon?",
      text: "Havaintoa ei voi palauttaa!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "grey",
      confirmButtonText: "Kyllä",
      cancelButtonText: "Peruuta",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteObservationMutation.mutate(id);
      }
    });
  };

  const createObs = (
    species: string,
    additional_info: string,
    date: string,
    keywords: string,
    single: ListTaxon
  ) => {
    const body = createObservationBody(
      location.search,
      species,
      additional_info,
      date,
      keywords
    );
    body.single = single;
    createObservationMutation.mutate(body);
  };

  const redirectToEditPage = (observationId: number) => {
    const obsWanted = observationsQuery.data?.observations.find(
      (obs) => obs.id === observationId
    );
    if (obsWanted) {
      const {
        id,
        species_id,
        additional_info,
        date_from,
        date_to,
        is_wp,
        continent_id,
        country_id,
        association_id,
        municipality_id,
        keyword_ids,
      } = obsWanted;
      const area = is_wp === 0 ? "2" : is_wp === 1 ? "1" : "3";

      let params = `?edit=${id}&area=${area}&species=${species_id}`;
      if (date_to && date_from === date_to) {
        params += `&date=${date_to}`;
      } else {
        params += "&date=-1";
      }
      if (additional_info) {
        params += `&additional_info=${additional_info}`;
      }
      if (continent_id) {
        params += `&continent=${continent_id}`;
      }
      if (country_id) {
        params += `&country=${country_id}`;
      }
      if (association_id) {
        params += `&association=${association_id}`;
      }
      if (municipality_id) {
        params += `&municipality=${municipality_id}`;
      }
      if (keyword_ids) {
        params += `&keywords=${keyword_ids}`;
      }
      window.open(`/havainto${params}`, "_blank");
    } else {
      Swal.fire({
        icon: "error",
        title: "Jokin meni pieleen",
        text: "Havaintoa ei löytynyt.",
      });
    }
  };

  /** Export all functions */
  return {
    handleSearchParamChange,
    utilityReady,
    language,
    lockedSearchParams,
    paginationPage,
    setLanguage,
    getKeyWords,
    setPaginationPage,
    getListData,
    getObservations,
    getCollectionItems,
    getPossibleContinents,
    getPossibleCountries,
    getPossibleAssociations,
    getPossibleMunicipalities,
    getTaxons,
    deleteObs,
    createObs,
    redirectToEditPage,
  };
}
