import { useState } from "react";
import {
  fetchUserObservationCSV,
  getUser,
} from "../Utils/apiUtils";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";

export default function CSVDownloadButton() {
  const [loading, setLoading] = useState(false);

  const downloadCSV = async () => {
    try {
      setLoading(true);
      const data = await fetchUserObservationCSV();

      const blob = new Blob([data], { type: "text/csv" });
      const a = document.createElement("a");

      const user = getUser();
      const now = new Date().toLocaleDateString("fi-FI");

      a.download = `${
        user
          ? `${user.firstname}_${user.lastname}_havainnot_${now}`
          : `omat_havainnot_${now}`
      }.csv`;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        className="w-100 m-0 p-0 p-2 text-md"
        onClick={() => downloadCSV()}
      >
        {loading ? <LoadingSpinner small /> : "Lataa CSV"}
      </button>
    </div>
  );
}
