interface IObservationKeywordBubbgleProps {
  text: string;
  id: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function ObservationKeywordBubble(
  props: IObservationKeywordBubbgleProps
) {
  const { onClick, text, id } = props;
  return (
    <div
      id={id}
      className="observation-keyword-bubble text-center"
      onClick={onClick}
    >
      {text.length < 15 ? text : text.slice(0, 15) + "..."}
    </div>
  );
}

export default ObservationKeywordBubble;
