import {
  format as dateFormat,
  parse as dateParse,
} from "date-fns";
import fin from "date-fns/locale/fi";
import ReactDatePicker, {
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("fi", fin);

interface IconButtonProps {
  /** bootstrap icon appears besides the input */
  icon: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface CustomDateInputProps {
  valueSelected: string;
  name: string;
  cleanOnFocus: boolean;
  placeHolder?: string;
  onFinishChange: (value: string | null) => void;
  icon?: IconButtonProps;
  /** Validation rules for dates */
  validate?: (date: Date) => boolean;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  onLoseFocus?: (e: React.FocusEvent<any>) => void;
  disabled?: boolean;
}

function CustomDateInput(props: CustomDateInputProps) {
  const {
    valueSelected,
    name,
    onFinishChange,
    validate,
    icon,
    placeHolder,
    onKeyDown,
    onLoseFocus,
    disabled,
  } = props;

  const handleBlur = (value: Date | null) => {
    if (validate && value !== null && !validate(value)) {
      return onFinishChange(null);
    }
    onFinishChange &&
      onFinishChange(
        value ? dateFormat(value, "yyyy-MM-dd") : null
      );
  };

  const getDate = () => {
    if (valueSelected && valueSelected !== "") {
      return dateParse(valueSelected, "yyyy-MM-dd", new Date());
    }
    return null;
  };

  return (
    <div className="input-container">
      <ReactDatePicker
        className="w-100 p-2"
        name={name}
        dateFormat={"dd.MM.yyyy"}
        selected={getDate()}
        onChange={handleBlur}
        disabled={disabled}
        locale="fi"
        enableTabLoop={false}
        preventOpenOnFocus={true}
        onBlur={(e) => {
          onLoseFocus && onLoseFocus(e);
        }}
        onKeyDown={(e) => {
          onKeyDown && onKeyDown(e);
        }}
        placeholderText={placeHolder}
      />
      {icon && (
        <div
          className="custom-input-icon-button d-flex justify-content-center align-items-center"
          id={name}
          onClick={icon.onClick}
        >
          <i className={icon.icon}></i>
        </div>
      )}
    </div>
  );
}

export default CustomDateInput;
