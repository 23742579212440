import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import CustomOptionInput from "../../Components/CustomInputs/CustomOptionInput";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useLocalStorageValue from "../../Hooks/useLocalStorageValue";
import { CompareResponse } from "../../Types/apiResponses";
import {
  fetchCompare,
  fetchListUserNames,
} from "../../Utils/apiUtils";
import { useTableSort } from "../../Utils/generalUtils";
import "./styles.scss";

function ComparePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<
    | "ioc_finnish"
    | "ioc_english"
    | "ioc_scientific"
    | "scientific_abbreviation"
  >("ioc_finnish");
  const [serach, setSearch] = useState("");
  const [displayMode, setDisplayMode] = useState<
    "only-user-1" | "only-user-2" | "both"
  >("both");
  const queryClient = useQueryClient();

  const compareQuery = useQuery(
    "compare-in-list",
    () => fetchCompare(location.search),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const [defaultLanguage, setDefaultLanguage] =
    useLocalStorageValue<
      | "ioc_finnish"
      | "ioc_english"
      | "ioc_scientific"
      | "scientific_abbreviation"
      | null
    >("editor-default-language", null);

  useEffect(() => {
    if (defaultLanguage) {
      setLanguage(defaultLanguage);
    }
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const [order, setOrderKey] = useTableSort(
    compareQuery.data?.compares || []
  );

  const compareUsersQuery = useQuery(["compare-users"], () =>
    fetchListUserNames(
      new URLSearchParams(location.search).get("listId")
    )
  );

  const parseDate = (date: string | undefined) => {
    if (!date) return "0.0.0000";
    return format(new Date(date), "dd.MM.yyyy");
  };

  const changeLanguage = (
    language:
      | "ioc_finnish"
      | "ioc_english"
      | "ioc_scientific"
      | "scientific_abbreviation"
  ) => {
    setLanguage(language);
    setDefaultLanguage(language);
  };

  /** navigate to same url, but change userIdFrom or userIdTo accordring to wether which is to or from
   * keep other search parameters the same
   */
  const changeUserId = (id: string, which: "to" | "from") => {
    const searchParams = new URLSearchParams(location.search);
    if (which === "to") {
      searchParams.set("userIdTo", id);
    } else {
      searchParams.set("userIdFrom", id);
    }
    const newUrl = `${
      location.pathname
    }?${searchParams.toString()}`;
    setDisplayMode("both");
    navigate(newUrl);
  };

  const getUserName = (which: "to" | "from") => {
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get(
      which === "to" ? "userIdTo" : "userIdFrom"
    );
    const user = compareUsersQuery.data?.find(
      (user) => user.id.toString() === userId
    );
    return `${user?.firstname} ${user?.lastname}` || "...";
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    /** Only fetch when needed search params are present */
    if (
      searchParams.get("userIdFrom") &&
      searchParams.get("userIdTo") &&
      searchParams.get("listId")
    ) {
      if (
        searchParams.get("userIdFrom") == "-1" ||
        searchParams.get("userIdTo") == "-1"
      )
        return;
      compareQuery.refetch();
      compareUsersQuery.refetch();
    }
    if (
      !searchParams.get("userIdFrom") ||
      !searchParams.get("userIdTo") ||
      !searchParams.get("listId")
    ) {
      queryClient.invalidateQueries("compare-in-list");
      queryClient.removeQueries("compare-in-list");
    }
  }, [location.search]);

  /**If displayMode is not both, only return where selected has_user is 1 and the other is 0 */
  const filterCompares = (compares: Array<CompareResponse>) => {
    let result: Array<CompareResponse> = [];
    if (displayMode === "only-user-1") {
      result = compares.filter(
        (compare) =>
          compare.has_user_1 === 1 && compare.has_user_2 === 0
      );
    }
    if (displayMode === "only-user-2") {
      result = compares.filter(
        (compare) =>
          compare.has_user_1 === 0 && compare.has_user_2 === 1
      );
    }
    if (displayMode === "both") {
      result = compares;
    }

    if (serach !== "") {
      result = result.filter((compare) =>
        compare[language]
          .toLowerCase()
          .includes(serach.toLowerCase())
      );
    }
    return result;
  };

  const changeDisplayMode = (
    mode: "only-user-1" | "only-user-2" | "both"
  ) => {
    if (mode === displayMode) {
      setDisplayMode("both");
    } else {
      setDisplayMode(mode);
    }
  };

  return (
    <>
      <div className="row w-100 m-0 p-0 justify-content-center my-4">
        <div className="col-12 col-lg-8">
          <div className="w-100 row m-0 p-0 p-2">
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Lista: </b>
                {compareQuery.data?.list.name || "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Kuvaus: </b>
                {compareQuery.data?.list.description || "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Aika: </b>
                {parseDate(compareQuery.data?.list.date_from)}
              </p>
            </div>
          </div>
          <div className="w-100 text-center gap-2 row justify-content-start m-0 p-0 px-2 pt-1 pb-3">
            <div
              className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
              onClick={() => changeLanguage("ioc_finnish")}
              style={{
                backgroundColor:
                  language === "ioc_finnish"
                    ? "#ffbe00"
                    : "#a07702",
              }}
            >
              FI
            </div>
            <div
              className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
              onClick={() => changeLanguage("ioc_english")}
              style={{
                backgroundColor:
                  language === "ioc_english"
                    ? "#ffbe00"
                    : "#a07702",
              }}
            >
              EN
            </div>
            <div
              style={{
                backgroundColor:
                  language === "ioc_scientific"
                    ? "#ffbe00"
                    : "#a07702",
              }}
              className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
              onClick={() => changeLanguage("ioc_scientific")}
            >
              SCI
            </div>

            <div
              style={{
                backgroundColor:
                  language === "scientific_abbreviation"
                    ? "#ffbe00"
                    : "#a07702",
              }}
              className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
              onClick={() =>
                changeLanguage("scientific_abbreviation")
              }
            >
              3+3
            </div>
          </div>
          <div className="mb-3 w-100 px-2">
            <input
              type="text"
              className="w-100 p-1"
              placeholder={"hae"}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="row w-100 m-0 p-0 px-2">
            <div className="col-6 col-sm-4 p-0 m-0">
              {compareUsersQuery.data && (
                // <ComparePageChoose
                //   onChange={(e) =>
                //     changeUserId(e.target.value, "from")
                //   }
                //   users={compareUsersQuery.data}
                //   defaultText={"Minä"}
                // />
                <CustomOptionInput
                  cleanOnFocus={true}
                  possibleValues={compareUsersQuery.data.map(
                    (user) => ({
                      value: user.id.toString(),
                      label: `${user.firstname} ${user.lastname}`,
                    })
                  )}
                  labelKey="label"
                  name="user-from"
                  valueKey="value"
                  placeHolder="Valitse henkilö"
                  valueSelected={
                    searchParams.get("userIdFrom") || ""
                  }
                  onFinishChange={(val) => {
                    if (!val?.value) {
                      searchParams.delete("userIdFrom");
                      setSearchParams(searchParams);
                      queryClient.invalidateQueries(
                        "compare-users"
                      );
                      return;
                    }

                    changeUserId(val?.value, "from");
                  }}
                />
              )}
            </div>
            <div className="col-6 col-sm-4 p-0 m-0 ps-2">
              {compareUsersQuery.data && (
                <CustomOptionInput
                  cleanOnFocus={true}
                  possibleValues={compareUsersQuery.data.map(
                    (user) => ({
                      value: user.id.toString(),
                      label: `${user.firstname} ${user.lastname}`,
                    })
                  )}
                  labelKey="label"
                  name="user-to"
                  valueKey="value"
                  placeHolder="Valitse henkilö"
                  valueSelected={
                    searchParams.get("userIdTo") || ""
                  }
                  onFinishChange={(val) => {
                    if (!val?.value) {
                      searchParams.delete("userIdTo");
                      setSearchParams(searchParams);
                      queryClient.invalidateQueries(
                        "compare-users"
                      );
                      return;
                    }

                    changeUserId(val?.value, "to");
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row w-100 m-0 p-0 justify-content-center main-container py-3">
        <div className="col-12 col-lg-8">
          <div className="w-100 row m-0 p-0 observations-container p-2 pb-2">
            {compareQuery.isFetching ? (
              <div className="row w-100 m-0 p-0 py-2 justify-content-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {compareQuery.data ? (
                  <div className="table-responsive m-0 p-0">
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th scope="col">
                            <b>#</b>
                          </th>
                          <th
                            scope="col"
                            className="table-column-pressable "
                            onClick={() =>
                              setOrderKey("systord")
                            }
                          >
                            Laji
                          </th>
                          <th
                            scope="col"
                            className="table-column-pressable "
                            onClick={() =>
                              changeDisplayMode("only-user-1")
                            }
                          >
                            {getUserName("from")}
                          </th>
                          <th
                            scope="col"
                            className="table-column-pressable "
                            onClick={() =>
                              changeDisplayMode("only-user-2")
                            }
                          >
                            {getUserName("to")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order &&
                          (
                            filterCompares(
                              order
                            ) as CompareResponse[]
                          ).map((compare, index) => {
                            return (
                              <tr key={index}>
                                <td>{`${index + 1}.`}</td>
                                <td>{compare[language]}</td>
                                <td>
                                  {compare.has_user_1 === 1 ? (
                                    <h5 className="m-0 p-0">
                                      <i className="bi bi-check-lg green"></i>
                                    </h5>
                                  ) : (
                                    <h5 className="m-0 p-0">
                                      <i className="bi bi-x-lg red"></i>
                                    </h5>
                                  )}
                                </td>
                                <td>
                                  {compare.has_user_2 === 1 ? (
                                    <h5 className="m-0 p-0">
                                      <i className="bi bi-check-lg green"></i>
                                    </h5>
                                  ) : (
                                    <h5 className="m-0 p-0">
                                      <i className="bi bi-x-lg red"></i>
                                    </h5>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="py-2 m-0">
                    <b>Valitse henkilöt joita verrataan</b>
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ComparePage;
