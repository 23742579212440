import Container from "../../Components/Container";
import ObservationEditorForm from "./ObservationEditorForm";
import ObservationEditorList from "./ObservationEditorList";

function ObservationEditorPage() {
  return (
    <>
      <Container>
        <ObservationEditorForm />
      </Container>
      <Container className="main-container">
        <ObservationEditorList />
      </Container>
    </>
  );
}

export default ObservationEditorPage;
