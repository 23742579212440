import { HTMLAttributes } from "react";

interface IContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  children?:
    | JSX.Element
    | Array<JSX.Element | boolean | undefined>;
}

/**Full width container with row and centered d-flex elements*/
function Container(props: IContainerProps) {
  const { children, ...rest } = props;
  return (
    <div className="container-fluid m-0 p-0" {...rest}>
      <div className="row w-100 m-0 p-0 justify-content-center">
        {children}
      </div>
    </div>
  );
}

export default Container;
