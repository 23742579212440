import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

interface IconButtonProps {
  /** bootstrap icon appears besides the input */
  icon: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface CustomFreeInputProps {
  valueSelected: string;
  name: string;
  placeHolder: string;
  cleanOnFocus: boolean;
  onFinishChange: (value: string | null) => void;
  icon?: IconButtonProps;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  onKeyUp?: (e: React.KeyboardEvent<any>) => void;
  isTextArea?: boolean;
}

function CustomFreeInput(props: CustomFreeInputProps) {
  const {
    valueSelected,
    placeHolder,
    onFinishChange,
    name,
    cleanOnFocus,
    onKeyDown,
    icon,
    onKeyUp,
    isTextArea = false,
  } = props;

  const [inputText, setInputText] = useState<string>("");
  const [hasFocus, setHasFocus] = useState(false);
  const [isTooLong, setTooLong] = useState(false);
  const [height, setHeight] = useState("auto");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const shadowRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (isTextArea && shadowRef.current) {
      setHeight(`${shadowRef.current.scrollHeight}px`);
    }
  }, [inputText, isTextArea]);

  const handleBlur = () => {
    setHasFocus(false);
    onFinishChange && onFinishChange(inputText);
  };

  useEffect(() => {
    setTooLong(inputText.length > 300);
  }, [inputText]);

  const handleFocus = () => {
    setHasFocus(true);
    if (cleanOnFocus) {
      setInputText("");
    } else {
      setInputText(valueSelected);
    }
  };

  const chooseCurrentValue = () => {
    if (hasFocus) {
      return inputText;
    }
    return valueSelected;
  };

  return (
    <div className="input-container m-0 p-0">
      {isTextArea ? (
        <>
          <textarea
            ref={shadowRef}
            value={inputText}
            className="w-100 m-0 p-2 pe-5 shadow-textarea"
            style={{
              height: "auto",
              visibility: "hidden",
              position: "absolute",
              zIndex: -1,
              overflow: "hidden",
            }}
            readOnly
          />
          <textarea
            ref={textareaRef}
            value={chooseCurrentValue()}
            name={name}
            onChange={(e) => setInputText(e.target.value)}
            onBlur={handleBlur}
            placeholder={placeHolder}
            onFocus={handleFocus}
            autoComplete="off"
            className={clsx({
              "w-100 m-0 p-2 pe-5": true,
              "textarea-over-limit": isTooLong,
            })}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            style={{ height: height, overflow: "hidden" }}
          />
        </>
      ) : (
        <input
          type="text"
          value={chooseCurrentValue()}
          name={name}
          onChange={(e) => setInputText(e.target.value)}
          onBlur={handleBlur}
          placeholder={placeHolder}
          onFocus={handleFocus}
          autoComplete="off"
          className="w-100 p-2 pe-5"
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
        />
      )}
      {icon && (
        <div
          className="custom-input-icon-button d-flex justify-content-center align-items-center"
          style={{ bottom: isTextArea ? "3px" : "0" }}
          id={name}
          onClick={icon.onClick}
        >
          <i className={icon.icon}></i>
        </div>
      )}
    </div>
  );
}

export default CustomFreeInput;
