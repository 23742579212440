import { get as lsGet, remove as lsRemove } from "local-storage";
import { useLocation } from "react-router-dom";
import { ReactComponent as AddIcon } from "../Images/add.svg";
import { ReactComponent as ListIcon } from "../Images/list-format.svg";
import { ReactComponent as ListUserIcon } from "../Images/list-user.svg";
import HrefWrapper from "./HrefWrapper";

/**Navigation component for pinnari functionalities, below main header */
export default function DashBar() {
  const location = useLocation();
  const classNameByCurrentPath = (path: string) => {
    return path.includes(location.pathname)
      ? "col-lg-2 col-md-3 col-sm-5 col-6 justify-content-center text-center dark-negate dash-bar-element dark-select"
      : "col-lg-2 col-md-3 col-sm-5 col-6 justify-content-center text-center dark-negate dash-bar-element";
  };

  const getIcon = (iconName: "ADD" | "LIST" | "USER") => {
    switch (iconName) {
      case "ADD":
        return <AddIcon className="custom-icon" width={25} />;
      case "LIST":
        return <ListIcon className="custom-icon" width={25} />;
      case "USER":
        return (
          <ListUserIcon className="custom-icon" width={25} />
        );
    }
  };

  return (
    <div className="container-fluid bg-white m-0 p-0">
      <div className="row w-100 justify-content-center m-0 p-0">
        <DashBarNav
          icon="LIST"
          route={`/listat${
            lsGet("own-by-default") ? "?own=on" : ""
          }`}
          text="Pinnalistat"
        />
        <DashBarNav
          icon="ADD"
          route="/havainto"
          text="Lisää havainto"
        />
      </div>
    </div>
  );

  function DashBarNav({
    icon,
    route,
    text,
  }: {
    icon: "ADD" | "LIST" | "USER";
    route: string;
    text: string;
  }) {
    return (
      <div className={classNameByCurrentPath(route)}>
        <HrefWrapper
          onClick={() => lsRemove("last-open")}
          href={route}
        >
          <div className="d-flex justify-content-center my-2">
            {getIcon(icon)}
          </div>
          <div className="row">
            <p>{text}</p>
          </div>
        </HrefWrapper>
      </div>
    );
  }
}
