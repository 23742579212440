import { format as dateFormat } from "date-fns";
import { useSearchParams } from "react-router-dom";
import HrefWrapper from "../../Components/HrefWrapper";
import { LatestObservationsList } from "../../Types/apiResponses";

interface LatestObservationItemProps {
  observation: LatestObservationsList;
  language:
    | "ioc_finnish"
    | "ioc_english"
    | "ioc_scientific"
    | "scientific_abbreviation";
  listId: string;
}

function LatestObservationItem({
  observation,
  language,
  listId,
}: LatestObservationItemProps) {
  const [searchParams] = useSearchParams();

  const parsePlace = () => {
    const {
      continent_name,
      country_name,
      association_name,
      municipality_name,
    } = observation;
    if (municipality_name) return municipality_name;
    if (association_name) return association_name;
    if (country_name) return country_name;
    if (continent_name) return continent_name;
    return "";
  };

  const formatTimestamp = (date: string) => {
    if (!date) return "0.0.0000";
    try {
      return dateFormat(new Date(date), "dd.MM.yyyy");
    } catch (error) {
      return;
    }
  };

  return (
    <tr>
      <td
        className={
          searchParams.get("speciesId") !== null
            ? ""
            : "table-column-pressable"
        }
      >
        <HrefWrapper
          href={`/tuoreimmat/${listId}?speciesId=${observation.species_id}`}
          disabled={searchParams.get("speciesId") !== null}
        >
          {observation[language] || ""}
        </HrefWrapper>
      </td>
      <td
        className={
          searchParams.get("userId") !== null
            ? ""
            : "table-column-pressable"
        }
      >
        <HrefWrapper
          href={`/tuoreimmat/${listId}?userId=${observation.user_id}`}
          disabled={searchParams.get("userId") !== null}
        >
          {`${observation.firstname} ${observation.lastname}` ||
            ""}
        </HrefWrapper>
      </td>
      <td>{parsePlace()}</td>
      <td>{formatTimestamp(observation.date_to) || ""}</td>
      <td>{observation.additional_info || ""}</td>
    </tr>
  );
}

export default LatestObservationItem;
