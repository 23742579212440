import clsx from "clsx";
import { useState } from "react";
import Swal from "sweetalert2";
import {
  UserListOrder,
  UserSettings,
} from "../../Hooks/useUserSettings";

function OrderSettings({
  userSettings,
  open,
  handleDeleteOrder,
  handleAddOrder,
  handleSetDefaultOrder,
  close,
}: {
  userSettings: UserSettings | null;
  open: boolean;
  handleDeleteOrder: (order: UserListOrder) => void;
  handleAddOrder: () => void;
  handleSetDefaultOrder: (order: UserListOrder) => void;
  close: () => void;
}) {
  const [currentSelections, setCurrentSelections] = useState<
    UserListOrder | undefined
  >(undefined);

  const handleChange = (e: any) => {
    if (!e.target.value) {
      setCurrentSelections(undefined);
      return;
    }
    const selectedOrder = userSettings?.userListOrders.find(
      (order) => order.name === e.target.value
    );
    setCurrentSelections(selectedOrder);
  };

  return (
    <div
      className={clsx("settings-popup-container", {
        "d-none": !open,
      })}
    >
      <div className="settings-popup p-3 col-sm-12 col-md-8 col-lg-6 justify-content-center align-items-start">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h5>Järjestysasetukset</h5>
          </div>
          <div className="col-12 mb-2">
            <select
              onChange={(e) => handleChange(e)}
              className="w-100"
              value={currentSelections?.name || ""}
            >
              <option value={""}>Valitse järjestys</option>
              {userSettings?.userListOrders.map(
                (order, index) => (
                  <option key={index} value={order.name}>
                    {order.name}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="col-12 col-md-4">
            <button
              onClick={() => {
                handleAddOrder();
              }}
              className="btn btn-primary w-100"
            >
              Uusi
            </button>
          </div>
          <div className="col-12 col-md-4">
            <button
              onClick={() => {
                if (!currentSelections) return;
                Swal.fire({
                  title: "Oletko varma",
                  text: "Haluatko varmasti poistaa järjestyksen?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Kyllä",
                  cancelButtonText: "Peruuta",
                }).then((result) => {
                  if (!result.isConfirmed) return;
                  handleDeleteOrder(currentSelections);
                  setCurrentSelections(undefined);
                });
              }}
              className="btn btn-primary w-100"
            >
              Poista
            </button>
          </div>
          <div className="col-12 col-md-4">
            <button
              onClick={() => {
                if (!currentSelections) return;
                handleSetDefaultOrder(currentSelections);
              }}
              className="btn btn-primary w-100"
            >
              Käytä
            </button>
          </div>
        </div>
        <div className="row w-100 justify-content-center m-0 p-0 mt-2">
          <div className="col-6 col-md-3">
            <button
              onClick={() => {
                close();
              }}
              className="btn btn-primary w-100 py-1"
            >
              Valmis
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSettings;
