import { format } from "date-fns";
import { ChangeEvent, useEffect, useState } from "react";
import { FilteredObservationResponse } from "../Types/apiResponses";

/**Parse form data and return it as an object*/
export const parseFormData = (
  e: ChangeEvent<HTMLFormElement>
): any => {
  e.preventDefault();
  const myFormData = new FormData(e.target);
  const formDataObj: any = {};
  myFormData.forEach((value, key) => (formDataObj[key] = value));
  return formDataObj;
};

/**Constructs a query out of kv-pairs */
export const createSearchQueryFromPairs = (
  pairs: any
): string => {
  let searchQuery = "?";
  let appended = false;

  Object.keys(pairs).forEach((key) => {
    if (pairs[key] && pairs[key] != -1 && pairs[key] != "") {
      searchQuery += `${key}=${pairs[key]}&`;
      appended = true;
    }
  });

  if (appended) {
    searchQuery = searchQuery.substring(
      0,
      searchQuery.length - 1
    );
  }
  return searchQuery;
};

export const createPairsFromSearchQuery = (
  searchQuery: string
) => {
  const urlParams = new URLSearchParams(searchQuery);
  return Object.fromEntries(urlParams);
};

/** Semmonen setti */
export const useTableSort = (list: any) => {
  const [state, setState] = useState(list);
  const [asc, setAscending] = useState(true);
  const [key, setKey] = useState("systord");

  useEffect(() => {
    setState(list);
  }, [list]);

  const getCompareFunction = (
    newKey: string,
    ascending: boolean
  ) => {
    return (a: any, b: any) => {
      if (newKey === "score_gain_this_year") {
        a[newKey] = Number(a[newKey]);
        b[newKey] = Number(b[newKey]);
      }

      // Special case for date_from
      if (newKey === "date_from") {
        // If a's date_from is null, it should go to the bottom
        if (a[newKey] === null || a[newKey] === undefined) {
          return 1;
        }
        // If b's date_from is null, it should go to the bottom
        if (b[newKey] === null || b[newKey] === undefined) {
          return -1;
        }

        // Reverse the sort order for date_from
        if (a[newKey] < b[newKey]) {
          return ascending ? 1 : -1;
        } else if (a[newKey] > b[newKey]) {
          return ascending ? -1 : 1;
        }
      }
      // General case
      else {
        if (a[newKey] < b[newKey]) {
          return ascending ? -1 : 1;
        } else if (a[newKey] > b[newKey]) {
          return ascending ? 1 : -1;
        }
      }

      return 0;
    };
  };

  const sortList = (newKey: string, ascending: boolean) => {
    const res = list.sort(getCompareFunction(newKey, ascending));
    setState(res);
  };

  const sortBy = (newKey: string): void => {
    const isAscending = newKey === key ? !asc : true;
    newKey === key ? setAscending(!asc) : setAscending(true);

    const keyMapping: { [key: string]: string } = {
      systord: "ioc_systord",
      date: "date_from",
      name: "lastname",
      score: "score",
      change: "score_gain_this_year",
      count: "have_seen",
    };

    if (
      newKey in keyMapping &&
      Object.keys(list[0]).includes(keyMapping[newKey])
    ) {
      sortList(keyMapping[newKey], isAscending);
    }

    setKey(newKey);
  };

  return [state, sortBy, setState];
};

export const signOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.href = "/";
};

export const parseDate = (
  parseObject: FilteredObservationResponse | undefined
) => {
  const {
    date_from,
    date_to,
    interval_monthday_from,
    interval_monthday_to,
  } = parseObject || {};

  if (date_to && date_from && date_to !== date_from) {
    return (
      format(new Date(date_from), "dd.MM.yyyy") +
      " - " +
      format(new Date(date_to), "dd.MM.yyyy")
    );
  }
  if (date_to) {
    return format(new Date(date_to), "dd.MM.yyyy");
  }
  if (interval_monthday_from && interval_monthday_to) {
    const interval_string_from =
      interval_monthday_from >= 1000
        ? interval_monthday_from.toString()
        : "0" + interval_monthday_from.toString();
    const interval_string_to =
      interval_monthday_to >= 1000
        ? interval_monthday_to.toString()
        : "0" + interval_monthday_to.toString();
    const formattedFrom =
      interval_string_from.slice(2, 4) +
      "." +
      interval_string_from.slice(0, 2);
    const formattedTo =
      interval_string_to.slice(2, 4) +
      "." +
      interval_string_to.slice(0, 2);
    return formattedFrom + " - " + formattedTo;
  }
  return "0.0.0000";
};

/** Hardcoded because timetables*/
export const getAreaFromContinent = (id: number) => {
  const CONTINENTS_TO_STATE = [
    {
      id: 1,
      area: 3,
    },
    {
      id: 2,
      area: 3,
    },
    {
      id: 3,
      area: 2,
    },
    {
      id: 4,
      area: 2,
    },
    {
      id: 5,
      area: 2,
    },
    {
      id: 6,
      area: 1,
    },
    {
      id: 7,
      area: 2,
    },
    {
      id: 8,
      area: 1,
    },
  ];

  let result = 3;
  CONTINENTS_TO_STATE.forEach((continent) => {
    if (continent.id === id) result = continent.area;
  });
  /** default to world */
  return result;
};
