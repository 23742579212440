interface ICustomCheckBoxProps {
  checked: boolean;
  onChange: (event: React.MouseEvent<HTMLDivElement>) => any;
  label?: string;
  icon?: {
    checked: string;
    unchecked: string;
  };
  colors?: {
    checked: string;
    unchecked: string;
    hover?: string;
  };
  size: number;
}

function CustomCheckBox(props: ICustomCheckBoxProps) {
  const { checked, onChange, size } = props;
  let { icon, colors } = props;
  if (!icon) {
    icon = {
      checked: "bi bi-check-square",
      unchecked: "bi bi-square",
    };
  }
  if (!colors) {
    colors = {
      checked: "green",
      unchecked: "gray",
      hover: "lightgray",
    };
  }

  return (
    <div
      style={{
        height: size,
        width: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={onChange}
    >
      <i
        className={checked ? icon.checked : icon.unchecked}
        style={{
          color: checked ? colors.checked : colors.unchecked,
          fontSize: size * 0.8,
        }}
      />
    </div>
  );
}

export default CustomCheckBox;
