import { Link } from "react-router-dom";

/**
 * Component that wraps href around child compoents to provide open in new window etc functionality
 * Has no styles to be easy for plug in
 * @returns
 */
interface HrefWrapperProps {
  children: React.ReactNode;
  href: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  classname?: string;
  disabled?: boolean;
  onClick?: () => void;
}

function HrefWrapper({
  children,
  href,
  target = "_self",
  classname,
  disabled = false,
  onClick,
}: HrefWrapperProps) {
  if (disabled) {
    return <>{children}</>;
  }
  return (
    <Link
      onClick={(e) => {
        onClick && onClick();
      }}
      className={"href-no-style w-100 h-100 " + classname}
      to={href}
      target={target}
    >
      {children}
    </Link>
  );
}

export default HrefWrapper;
