import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

/**404 page with nice svg image and everything centered */
function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className="position-absolute start-50 top-50 translate-middle text-center">
      <motion.h1
        style={{ fontSize: "9em" }}
        initial={{ scale: 0.1 }}
        animate={{ scale: 1 }}
        transition={{ duration: 1.5 }}
      >
        404
      </motion.h1>
      <motion.h4
        initial={{ opacity: 0.1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        Tämäpä kiusallista, tätä sivua ei ole
      </motion.h4>
      <motion.button
        onClick={() => {
          navigate(-1);
        }}
        initial={{ opacity: 0.1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        Takaisin?
      </motion.button>
    </div>
  );
}

export default NotFoundPage;
