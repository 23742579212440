import { useEffect, useState } from "react";
import HrefWrapper from "../../Components/HrefWrapper";
import { HasSeenSpeciesCountResponse } from "../../Types/apiResponses";
import { useTableSort } from "../../Utils/generalUtils";

import useLocalStorageValue from "../../Hooks/useLocalStorageValue";
import "./styles.scss";

interface PinnarListSpeciesDetailsProps {
  listSpeciesDetails: Array<HasSeenSpeciesCountResponse>;
  filter: string;
  listId: number;
}

/** shows a table of user firstname + lastname, their score, score - score lastyear allows filtering names with filter prop*/
function PinnarListSpeciesDetails({
  listSpeciesDetails,
  filter,
  listId,
}: PinnarListSpeciesDetailsProps) {
  const [language, setLanguage] = useState<
    | "ioc_finnish"
    | "ioc_english"
    | "ioc_scientific"
    | "scientific_abbreviation"
  >("ioc_finnish");

  const [defaultLanguage, setDefaultLanguage] =
    useLocalStorageValue<
      | "ioc_finnish"
      | "ioc_english"
      | "ioc_scientific"
      | "scientific_abbreviation"
      | null
    >("editor-default-language", null);

  useEffect(() => {
    if (defaultLanguage) {
      setLanguage(defaultLanguage);
    }
  }, []);

  const filterNames = () => {
    return order.filter(
      (species: HasSeenSpeciesCountResponse) => {
        return (
          species[language] &&
          species[language]
            .toLowerCase()
            .includes(filter.toLowerCase())
        );
      }
    );
  };

  const [order, setOrderKey, setList] = useTableSort(
    listSpeciesDetails || []
  );

  useEffect(() => {
    setList(filterNames() || []);
  }, [listSpeciesDetails]);

  const getSeenObservationsHref = (
    speciesId: number,
    listId: number
  ) => {
    return `/havainneet?speciesId=${speciesId}&listId=${listId}`;
  };

  const changeLanguage = (
    language:
      | "ioc_finnish"
      | "ioc_english"
      | "ioc_scientific"
      | "scientific_abbreviation"
  ) => {
    setLanguage(language);
    setDefaultLanguage(language);
  };

  return (
    <div className="row w-100 m-0 p-0 p-0 justify-content-center">
      <div className="w-100 text-center gap-2 row m-0 p-0 pt-1 pb-3">
        <div
          className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
          onClick={() => changeLanguage("ioc_finnish")}
          style={{
            backgroundColor:
              language === "ioc_finnish" ? "#ffbe00" : "#a07702",
          }}
        >
          FI
        </div>
        <div
          className="col-2 col-sm-1 language-button rounded cursor-pointer"
          onClick={() => changeLanguage("ioc_english")}
          style={{
            backgroundColor:
              language === "ioc_english" ? "#ffbe00" : "#a07702",
          }}
        >
          EN
        </div>
        <div
          style={{
            backgroundColor:
              language === "ioc_scientific"
                ? "#ffbe00"
                : "#a07702",
          }}
          className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
          onClick={() => changeLanguage("ioc_scientific")}
        >
          SCI
        </div>
        <div
          style={{
            backgroundColor:
              language === "scientific_abbreviation"
                ? "#ffbe00"
                : "#a07702",
          }}
          className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
          onClick={() =>
            changeLanguage("scientific_abbreviation")
          }
        >
          3+3
        </div>
      </div>
      <div className="table-responsive m-0 p-0">
        <table className="table table-striped table-sm">
          <thead className="thead-dark">
            <tr>
              <th
                scope="col"
                className="d-hide d-none d-sm-table-cell"
              >
                #
              </th>

              <th
                scope="col"
                className="table-column-pressable"
                onClick={() => setOrderKey("systord")}
              >
                Laji
              </th>
              <th
                scope="col"
                className="table-column-pressable"
                onClick={() => setOrderKey("count")}
              >
                Havanneita
              </th>
            </tr>
          </thead>
          <tbody>
            {order &&
              (
                filterNames() as HasSeenSpeciesCountResponse[]
              ).map(
                (
                  species: HasSeenSpeciesCountResponse,
                  index: number
                ) => {
                  return (
                    <tr key={index}>
                      <th
                        scope="row"
                        className="d-hide d-none d-sm-table-cell"
                      >{`${index + 1}.`}</th>
                      <td className="table-column-pressable">
                        <HrefWrapper
                          href={getSeenObservationsHref(
                            species.species_id,
                            listId
                          )}
                        >
                          {species[language]}
                        </HrefWrapper>
                      </td>
                      <td>{species.have_seen}</td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PinnarListSpeciesDetails;
