import { format } from "date-fns";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import HrefWrapper from "../../Components/HrefWrapper";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useLocalStorageValue from "../../Hooks/useLocalStorageValue";
import { FilteredObservationResponse } from "../../Types/apiResponses";
import {
  deleteObservation,
  fetchObservationById,
} from "../../Utils/apiUtils";
import { getAreaFromContinent } from "../../Utils/generalUtils";

interface UserLocal {
  id: number;
}

function ObservationSinglePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const observationQuery = useQuery(
    ["observation-single", id],
    () => fetchObservationById(id as string),
    {
      retry: false,
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );

  const [user] = useLocalStorageValue<UserLocal | undefined>(
    "user",
    undefined
  );

  const deleteObsMutation = useMutation(
    (id: number) => deleteObservation(id),
    {
      onSuccess: () => {
        Swal.fire({
          icon: "success",
          title: "Havainto poistettu!",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          queryClient.invalidateQueries({
            queryKey: ["observation-single"],
          });
          observationQuery.refetch();
          navigate("/listat");
        });
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          title: "HUPS!",
          text: "Jokin meni pieleen havaintoa poistaessa",
        }).then(() => {
          queryClient.invalidateQueries({
            queryKey: ["observation-single"],
          });
          observationQuery.refetch();
        });
      },
    }
  );

  const handleDeleteObs = (id: number | undefined) => {
    if (!id) return;
    Swal.fire({
      title: "Haluatko varmasti poistaa havainnon?",
      text: "Havaintoa ei voi palauttaa!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "grey",
      confirmButtonText: "Kyllä",
      cancelButtonText: "Peruuta",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteObsMutation.mutate(id);
      }
    });
  };

  const editHref = (obs: FilteredObservationResponse) => {
    const {
      species_id,
      additional_info,
      date_to,
      date_from,
      continent_id,
      country_id,
      association_id,
      municipality_id,
      id,
      keyword_ids,
    } = obs;

    if (!id || !species_id) {
      return;
    }

    let dateToFormatted;
    if (date_from !== date_to || date_from === null) {
      dateToFormatted = "-1";
    } else if (date_to) {
      try {
        dateToFormatted = format(
          new Date(date_to),
          "yyyy-MM-dd"
        );
      } catch (error) {
        dateToFormatted = undefined;
      }
    }
    const area = getAreaFromContinent(continent_id);

    let redirectParams = `edit=${id}&species=${species_id}&area=${area}`;
    if (additional_info) {
      redirectParams += `&additional_info=${additional_info}`;
    }
    if (dateToFormatted) {
      redirectParams += `&date=${dateToFormatted}`;
    }
    if (continent_id) {
      redirectParams += `&continent=${continent_id}`;
    }
    if (country_id) {
      redirectParams += `&country=${country_id}`;
    }
    if (association_id) {
      redirectParams += `&association=${association_id}`;
    }
    if (municipality_id) {
      redirectParams += `&municipality=${municipality_id}`;
    }
    if (keyword_ids) {
      redirectParams += `&keywords=${keyword_ids}`;
    }
    const newUrl = `/havainto?${redirectParams}`;
    return newUrl;
  };

  const parseDate = (parseObject: any | undefined) => {
    const {
      date_from,
      date_to,
      interval_monthday_from,
      interval_monthday_to,
    } = parseObject || {};

    if (date_to && date_from && date_to !== date_from) {
      return (
        format(new Date(date_from), "dd.MM.yyyy") +
        " - " +
        format(new Date(date_to), "dd.MM.yyyy")
      );
    }
    if (date_from) {
      return format(new Date(date_from), "dd.MM.yyyy");
    }

    if (interval_monthday_from && interval_monthday_to) {
      const interval_string_from =
        interval_monthday_from >= 1000
          ? interval_monthday_from.toString()
          : "0" + interval_monthday_from.toString();
      const interval_string_to =
        interval_monthday_to >= 1000
          ? interval_monthday_to.toString()
          : "0" + interval_monthday_to.toString();
      const formattedFrom =
        interval_string_from.slice(2, 4) +
        "." +
        interval_string_from.slice(0, 2);
      const formattedTo =
        interval_string_to.slice(2, 4) +
        "." +
        interval_string_to.slice(0, 2);
      return formattedFrom + " - " + formattedTo;
    }
    return "0.0.0000";
  };

  const parsePlace = (seen: FilteredObservationResponse) => {
    const { country_name, municipality_name } = seen;
    if (municipality_name) {
      return municipality_name;
    }
    if (country_name) {
      return country_name;
    }
    return "";
  };

  return (
    <>
      <div className="row w-100 m-0 p-0 justify-content-center my-4">
        <div className="col-12 col-lg-8">
          <div className="w-100 row m-0 p-0 p-2">
            <div className="w-100 m-0 p-0">
              <p className="p-0 m-0">
                <b>Laji: </b>
                {observationQuery.data
                  ? `${
                      observationQuery.data?.ioc_finnish || ""
                    } | ${
                      observationQuery.data?.ioc_english || ""
                    } | ${
                      observationQuery.data?.ioc_scientific || ""
                    }`
                  : "..."}
              </p>
            </div>
            <div className="w-100 m-0 p-0">
              <p className="p-0 m-0">
                <b>Käyttäjä: </b>
                {observationQuery.data &&
                observationQuery.data.firstname &&
                observationQuery.data.lastname
                  ? `${observationQuery.data.firstname} ${observationQuery.data.lastname}`
                  : "..."}
              </p>
            </div>
            <div className="w-100 m-0 p-0">
              <p className="p-0 m-0">
                <b>Aika: </b>
                {parseDate(observationQuery.data)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row w-100 m-0 p-0 justify-content-center main-container py-3">
        <div className="col-12 col-lg-8 m-0 p-0">
          <div className="w-100 row m-0 p-0 observations-container pb-2">
            <div className="m-0 py-2 p-0 table-responsive">
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th scope="col" className="px-2">
                      <b>#</b>
                    </th>
                    <th
                      scope="col"
                      className="table-column-pressable"
                    >
                      Laji
                    </th>
                    <th
                      scope="col"
                      className="table-column-pressable"
                    >
                      Päivämäärä
                    </th>
                    <th scope="col">Paikka</th>
                    <th scope="col">Lisätiedot</th>
                    {observationQuery.data &&
                      user?.id ===
                        observationQuery.data.user_id && (
                        <th scope="col">Toiminnot</th>
                      )}
                  </tr>
                </thead>
                {observationQuery.isFetching && (
                  <div className="row w-100 m-0 p-0 py-2 justify-content-center">
                    <LoadingSpinner />
                  </div>
                )}
                {observationQuery.data &&
                  !observationQuery.isFetching && (
                    <tbody>
                      <tr>
                        <td className="px-2">1.</td>
                        <td>
                          {observationQuery.data.ioc_finnish}
                        </td>
                        <td>{`${parseDate(
                          observationQuery.data
                        )}`}</td>
                        <td>
                          {parsePlace(observationQuery.data)}
                        </td>
                        <td>
                          {observationQuery.data
                            .additional_info || ""}
                        </td>
                        {user?.id ===
                          observationQuery.data.user_id && (
                          <td className="text-start display-inline">
                            <HrefWrapper
                              href={
                                editHref(
                                  observationQuery.data
                                ) || "#"
                              }
                              classname="me-2"
                            >
                              <i className="bi bi-pencil-fill checkbox-tool-icon"></i>
                            </HrefWrapper>
                            <i
                              onClick={() => {
                                handleDeleteObs(
                                  observationQuery.data.id
                                );
                              }}
                              className="bi bi-trash-fill checkbox-tool-icon"
                            ></i>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ObservationSinglePage;
