import { getYear } from "date-fns";
import { remove as lsRemove, set as lsSet } from "local-storage";
import { map as _map } from "lodash";
import { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import FToggle from "../../Components/FormComponents/FToggle";
import { KeywordIndexResponse } from "../../Types/apiResponses";

interface PinnalistPageFilterFormProps {
  keywords: Array<KeywordIndexResponse>;
  queries: any;
  filterSumbit: (event: ChangeEvent<HTMLFormElement>) => void;
  setShowOwnOrder: (showOwnOrder: boolean) => void;
}

function PinnaListPageFilterForm({
  keywords,
  queries,
  filterSumbit,
  setShowOwnOrder,
}: PinnalistPageFilterFormProps) {
  const [searchParams, setSeachParams] = useSearchParams();

  return (
    <div className="container-fluid justify-content-center m-0 p-0 p-0 p-sm-1">
      <form onSubmit={filterSumbit}>
        <div className="row w-100 m-0 p-0 pt-2 pb-0">
          <div className="col-12 col-md-12 my-1">
            <select
              defaultValue={queries.place ? queries.place : -1}
              name="place"
              className="fselect p-2 dropdown-select"
            >
              <option value={-1}>Paikka</option>
              <option value={1}>Suomi</option>
              <option value={2}>WP</option>
              <option value={3}>Maailma</option>
            </select>
          </div>
          <div className="col-6 col-md-3 my-1">
            <select
              defaultValue={queries.month ? queries.month : -1}
              name="month"
              className="fselect p-2 dropdown-select"
            >
              <option value={-1}>Kuukausi</option>
              <option value={1}>Tammikuu</option>
              <option value={2}>Helmikuu</option>
              <option value={3}>Maaliskuu</option>
              <option value={4}>Huhtikuu</option>
              <option value={5}>Toukokuu</option>
              <option value={6}>Kesäkuu</option>
              <option value={7}>Heinäkuu</option>
              <option value={8}>Elokuu</option>
              <option value={9}>Syyskuu</option>
              <option value={10}>Lokakuu</option>
              <option value={11}>Marraskuu</option>
              <option value={12}>Joulukuu</option>
            </select>
          </div>
          <div className="col-6 col-md-3 my-1">
            <select
              defaultValue={queries.year ? queries.year : -1}
              name="year"
              className="fselect p-2 dropdown-select"
            >
              <option value={-1}>Vuosi</option>
              {yearOptions()}
            </select>
          </div>
          <div className="col-4 col-md-6 my-1">
            <div className="row w-100">
              <div className="col-12 col-md-5 order-sm-2 d-flex text-center justify-content-center align-items-center">
                <FToggle
                  defaultOn={queries.between === "on" || false}
                  id="search-between"
                  name="between"
                  onChange={() => {
                    setShowOwnOrder(false);
                  }}
                />
              </div>
              <div className="col-12 col-md-7 order-sm-1 d-flex text-center justify-content-center align-items-center">
                <p className="m-0">Aikaväli:</p>
              </div>
            </div>
          </div>
          <div className="col-4 col-md-6 my-1">
            <div className="row w-100">
              <div className="col-12 col-md-5 order-sm-2 d-flex text-center justify-content-center align-items-center">
                <FToggle
                  defaultOn={queries.own === "on" || false}
                  onChange={() => {
                    setShowOwnOrder(false);
                    lsRemove("last-open");
                    const ownOn =
                      searchParams.get("own") === "on";
                    if (ownOn) {
                      searchParams.delete("own");
                      lsSet("own-by-default", false);
                    } else {
                      searchParams.set("own", "on");
                      lsSet("own-by-default", true);
                    }
                    setSeachParams(searchParams);
                  }}
                  id="search-own"
                  name="own"
                />
              </div>
              <div className="col-12 col-md-7 order-sm-1 d-flex text-center justify-content-center align-items-center">
                <p className="m-0">Omat listat:</p>
              </div>
            </div>
          </div>

          <div className="col-4 col-md-6 my-1">
            <div className="row w-100">
              <div className="col-12 col-md-5 order-sm-2 d-flex text-center justify-content-center align-items-center">
                <FToggle
                  defaultOn={queries.hidden === "on" || false}
                  onChange={() => {
                    setShowOwnOrder(false);
                    lsRemove("last-open");
                    const ownOn =
                      searchParams.get("hidden") === "on";
                    if (ownOn) {
                      searchParams.delete("hidden");
                    } else {
                      searchParams.set("hidden", "on");
                    }
                    setSeachParams(searchParams);
                  }}
                  id="search-hidden"
                  name="hidden"
                />
              </div>
              <div className="col-12 col-md-7 order-sm-1 d-flex text-center justify-content-center align-items-center">
                <p className="m-0">Piilotetut:</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 my-1">
            <select
              defaultValue={
                queries.keyword ? queries.keyword : -1
              }
              name="keyword"
              className="fselect p-2 dropdown-select"
            >
              <option value={-1}>Asiasana</option>
              {_map(keywords, (keyword, index) => {
                return (
                  <option
                    key={"keyword-option-" + index}
                    value={keyword.id}
                  >
                    {keyword.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12 my-1 mb-0">
            <input
              defaultValue={
                queries.search ? queries.search : undefined
              }
              type="text"
              name="search"
              className="w-100 p-2 textbox"
              placeholder="Hakusana"
            />
          </div>

          <div className="col-12 my-2 text-center">
            <button className="w-100 p-2">Hae</button>
          </div>
        </div>
      </form>
    </div>
  );
}

/**Options for years*/
const yearOptions = () => {
  const years = [];
  for (let i = getYear(new Date()); i >= 1966; i--) {
    years.push(
      <option key={"year-option-" + i} value={i}>
        {i}
      </option>
    );
  }
  return years;
};

export default PinnaListPageFilterForm;
