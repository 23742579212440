import { useEffect } from "react";

import { format as dateFormat } from "date-fns";
import {
  ListShowResponse,
  listUserByListResponse,
} from "../../Types/apiResponses";
import { useTableSort } from "../../Utils/generalUtils";

import HrefWrapper from "../../Components/HrefWrapper";
import "./styles.scss";

interface PinnaListUserDetailsProps {
  listUserDetails: ListShowResponse;
  filter: string;
  listId: number;
}

/** shows a table of user firstname + lastname, their score, score - score lastyear allows filtering names with filter prop*/
function PinnaListUserDetails({
  listUserDetails,
  filter,
  listId,
}: PinnaListUserDetailsProps) {
  const filterNames = () => {
    return order.filter((user: listUserByListResponse) => {
      return (
        user.firstname
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        user.lastname
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        `${user.firstname} ${user.lastname}`
          .toLowerCase()
          .includes(filter.toLowerCase())
      );
    });
  };

  const userObservationsHref = (userId: number) => {
    return `/havainnot?userId=${userId}&listId=${listId}`;
  };

  const [order, setOrderKey, setList] = useTableSort(
    listUserDetails.user_scores || []
  );

  useEffect(() => {
    setList(filterNames() || []);
  }, [listUserDetails.user_scores]);

  const foramtDate = (date: string) => {
    const dateObj = new Date(date);
    try {
      return dateFormat(dateObj, "dd.MM.yyyy");
    } catch (e) {
      return "";
    }
  };

  return (
    <div className="row w-100 m-0 p-0 justify-content-center">
      <div className="table-responsive p-0 m-0">
        <table className="table table-striped table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th
                scope="col"
                className="table-column-pressable"
                onClick={() => setOrderKey("name")}
              >
                Nimi
              </th>
              <th
                scope="col"
                className="table-column-pressable"
                onClick={() => setOrderKey("score")}
              >
                Pinnoja
              </th>
              <th
                scope="col"
                className="table-column-pressable"
                onClick={() => setOrderKey("change")}
              >
                Muutos
              </th>
              <th
                scope="col"
                className="table-column"
              >
                Viimeisin
              </th>
            </tr>
          </thead>
          <tbody>
            {order &&
              (filterNames() as listUserByListResponse[]).map(
                (
                  user: listUserByListResponse,
                  index: number
                ) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{`${index + 1}.`}</th>
                      <td className="table-column-pressable">
                        <HrefWrapper
                          href={userObservationsHref(
                            user.user_id
                          )}
                        >
                          {user.firstname} {user.lastname}
                        </HrefWrapper>
                      </td>
                      <td>{user.score}</td>
                      <td>{`${
                        user.score_gain_this_year >= 0
                          ? "+" + user.score_gain_this_year
                          : user.score_gain_this_year
                      }`}</td>
                      <td scope="col">
                        {user.latest_observation
                          ? `${
                              user.latest_observation.ioc_finnish
                            } ${foramtDate(
                              user.latest_observation.date_to
                            )}`
                          : "-"}
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PinnaListUserDetails;
