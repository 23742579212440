import { map as _map } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../Components/Container";
import CustomDateInput from "../../Components/CustomInputs/CustomDateInput";
import CustomFreeInput from "../../Components/CustomInputs/CustomFreeInput";
import CustomOptionInput from "../../Components/CustomInputs/CustomOptionInput";
import FRadioButton from "../../Components/FormComponents/FRadioButton";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useLocalStorageValue from "../../Hooks/useLocalStorageValue";
import useObservationEditorUtilities from "../../Hooks/useObservationEditorUtilities";
import "../../Styles/FInputs.scss";
import {
  AssociationIndexResponse,
  ContinentIndexResponse,
  CountryIndexResponse,
  ExtendedTaxonOptionResponse,
  KeywordIndexResponse,
  MunicipalityIndexResponse,
} from "../../Types/apiResponses";
import ObservationKeywordBubble from "./ObservationKeywordBubble";

function ObservationEditorForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [taxonSuggestions, setTaxonSuggestions] = useState<
    ExtendedTaxonOptionResponse[]
  >([]);
  const [notClearedOnPost, setNotClearedOnPost] = useState<
    string[]
  >(["date"]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    initPageOnEdit,
    initPageParams,
    handleSpeciesLanguageChange,
    handleChangeParam,
    optionsReady,
    handleSubmitObservation,
    getPossibleTaxonOptions,
    getCollectionItems,
    getCollectionItemFieldById,
    getSpeciesNameById,
    handleKeywordChange,
    handleKeywordClick,
    parseKeywordIdsFromParams,
    handleFreeFormTaxonChange,
    submitting,
  } = useObservationEditorUtilities();

  const [
    defaultLanguage,
    setDefaultLanguage,
    removeDefaultLanguage,
  ] = useLocalStorageValue<
    | "ioc_finnish"
    | "ioc_english"
    | "ioc_scientific"
    | "scientific_abbreviation"
    | null
  >("editor-default-language", null);

  useEffect(() => {
    initPageParams();
    if (
      !searchParams.get("date") &&
      searchParams.get("date") !== "-1"
    ) {
      handleChangeParam(
        "date",
        new Date().toISOString().slice(0, 10)
      );
    }
  }, [searchParams.get("date")]);

  useEffect(() => {
    if (defaultLanguage && !searchParams.get("edit")) {
      handleChangeParam("language", defaultLanguage);
    }
    if (!searchParams.get("edit")) return;
    initPageOnEdit();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [searchParams.get("edit")]);

  const moveToSubmit = (e: React.KeyboardEvent<any>) => {
    if (e.type === "keydown" && e.key === "Enter") {
      e.currentTarget.blur();
      submit();
    }
  };

  const submit = () => {
    handleSubmitObservation(notClearedOnPost);
    setTimeout(() => {
      queryClient.invalidateQueries("own-observations");
      queryClient.invalidateQueries("taxon-check");
      queryClient.invalidateQueries("flagged-observations");
    }, 1000);
  };

  useEffect(() => {
    if (!searchParams.get("edit") && optionsReady()) {
      const language = searchParams.get("language");
      if (!language) {
        removeDefaultLanguage();
      } else {
        setDefaultLanguage(language as any);
      }
    }
  }, [searchParams.get("language")]);

  /** Get possible taxons */
  const updateTaxonOptions = (e: ChangeEvent<any>) => {
    const newValue = e.currentTarget.value;
    const possibleTaxons = getPossibleTaxonOptions(newValue);
    setTaxonSuggestions(possibleTaxons);
  };

  const toggleNotClearedOnPostField = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    const target = e.currentTarget.id;
    if (!target) return;
    if (notClearedOnPost.includes(target)) {
      setNotClearedOnPost(
        notClearedOnPost.filter((f) => f !== target)
      );
    } else {
      setNotClearedOnPost([...notClearedOnPost, target]);
    }
  };

  const isInNotClearedOnPost = (field: string) => {
    return notClearedOnPost.includes(field);
  };

  if (!optionsReady()) {
    return (
      <Container>
        <div className="row w-100 m-0 p-0 justify-content-center mt-5">
          <h4 className="m-0 p-0 text-center">
            Ladataan editoria...
          </h4>
          <LoadingSpinner />
        </div>
      </Container>
    );
  }

  return (
    <div className="col-12 col-sm-11 col-md-7 col-lg-6 col-xl-5 col-xxl-4 my-3">
      <div className="container-fluid m-0 p-0">
        <div className="row w-100 m-0 p-0 justify-content-start">
          <div className="col-6 my-1">
            <CustomOptionInput
              valueSelected={searchParams.get("area") || ""}
              cleanOnFocus={true}
              possibleValues={[
                {
                  name: "WP",
                  value: "1",
                },
                {
                  name: "Ei WP",
                  value: "2",
                },
                {
                  name: "Maailma",
                  value: "3",
                },
              ]}
              labelKey="name"
              valueKey="value"
              placeHolder="Alue"
              onFinishChange={(selected) =>
                handleChangeParam("area", selected?.value)
              }
              name="area"
            />
          </div>
          <div className="col-6 my-1">
            <CustomOptionInput
              valueSelected={searchParams.get("continent") || ""}
              cleanOnFocus={true}
              interractable={
                searchParams.get("area") ? true : false
              }
              possibleValues={getCollectionItems<ContinentIndexResponse>(
                "continents",
                (item) =>
                  searchParams.get("area")
                    ? searchParams.get("area") === "2"
                      ? item.nowp === 1
                      : searchParams.get("area") === "1"
                      ? item.wp === 1
                      : true
                    : true
              )}
              labelKey="name"
              valueKey="id"
              placeHolder="Maanosa"
              onFinishChange={(selected) =>
                handleChangeParam(
                  "continent",
                  selected?.id.toString()
                )
              }
              name="continent"
            />
          </div>
          <div className="col-6 my-1">
            <CustomOptionInput
              valueSelected={searchParams.get("country") || ""}
              cleanOnFocus={true}
              interractable={
                searchParams.get("continent") ? true : false
              }
              possibleValues={getCollectionItems<CountryIndexResponse>(
                "countries",
                (item) =>
                  searchParams.get("continent")
                    ? item.continent_id.toString() ===
                        searchParams.get("continent") &&
                      ((searchParams.get("area") === "1" &&
                        item.wp === 1) ||
                        (searchParams.get("area") === "2" &&
                          item.nowp === 1) ||
                        searchParams.get("area") === "3")
                    : true
              )}
              labelKey="name"
              valueKey="id"
              placeHolder="Maa"
              onFinishChange={(selected) =>
                handleChangeParam(
                  "country",
                  selected?.id.toString()
                )
              }
              name="country"
            />
          </div>
          <div className="col-6 my-1">
            <CustomOptionInput
              valueSelected={
                searchParams.get("association") || ""
              }
              cleanOnFocus={true}
              interractable={
                searchParams.get("country") ? true : false
              }
              possibleValues={getCollectionItems<AssociationIndexResponse>(
                "associations",
                (item) =>
                  searchParams.get("country")
                    ? item.country_id.toString() ===
                      searchParams.get("country")
                    : true
              )}
              labelKey="name"
              valueKey="id"
              placeHolder="Yhdistys"
              onFinishChange={(selected) =>
                handleChangeParam(
                  "association",
                  selected?.id.toString()
                )
              }
              name="association"
            />
          </div>
          <div className="col-6 my-1">
            <CustomOptionInput
              valueSelected={
                searchParams.get("municipality") || ""
              }
              cleanOnFocus={true}
              possibleValues={getCollectionItems<MunicipalityIndexResponse>(
                "municipalities",
                () => true
              )}
              labelKey="name"
              valueKey="id"
              placeHolder="Kunta"
              onFinishChange={(selected) =>
                handleChangeParam(
                  "municipality",
                  selected?.id.toString()
                )
              }
              name="municipality"
            />
          </div>
          <div className="row w-100 justify-align-content-between mx-3 my-4">
            <div className="col-3 my-1">
              <FRadioButton
                checkked={
                  searchParams.get("language") === "ioc_finnish"
                    ? true
                    : false
                }
                onClick={() => {
                  handleSpeciesLanguageChange("ioc_finnish");
                }}
                text="FI"
              />
            </div>
            <div className="col-3 my-1">
              <FRadioButton
                checkked={
                  searchParams.get("language") === "ioc_english"
                    ? true
                    : false
                }
                onClick={() => {
                  handleSpeciesLanguageChange("ioc_english");
                }}
                text="EN"
              />
            </div>
            <div className="col-3 my-1">
              <FRadioButton
                checkked={
                  searchParams.get("language") ===
                  "ioc_scientific"
                    ? true
                    : false
                }
                onClick={() => {
                  handleSpeciesLanguageChange("ioc_scientific");
                }}
                text="SCI"
              />
            </div>
            <div className="col-3 my-1">
              <FRadioButton
                checkked={
                  searchParams.get("language") ===
                  "scientific_abbreviation"
                    ? true
                    : false
                }
                onClick={() => {
                  handleSpeciesLanguageChange(
                    "scientific_abbreviation"
                  );
                }}
                text="3+3"
              />
            </div>
          </div>
          <div className="col-6 my-1">
            <CustomDateInput
              cleanOnFocus={true}
              valueSelected={
                searchParams.get("date") &&
                searchParams.get("date") !== "-1"
                  ? searchParams.get("date") || ""
                  : ""
              }
              onFinishChange={(selected) => {
                handleChangeParam("date", selected);
              }}
              onKeyDown={(e) => {
                moveToSubmit(e);
              }}
              placeHolder={
                searchParams.get("date") === "-1"
                  ? "Ei muutosta"
                  : searchParams.get("date") || ""
              }
              validate={(date: Date) => date <= new Date()}
              name="date"
            />
          </div>
          <div className="col-6 my-1">
            <CustomOptionInput
              cleanOnFocus={true}
              possibleValues={getCollectionItems<KeywordIndexResponse>(
                "keywords",
                () => true
              )}
              labelKey="name"
              valueKey="name"
              onFinishChange={(selected) => {
                selected?.id &&
                  handleKeywordChange(selected.id.toString());
              }}
              placeHolder="Asiasanat"
              name="keywords"
              onKeyDown={(e) => {
                moveToSubmit(e);
              }}
              valueSelected=""
              icon={{
                icon: isInNotClearedOnPost("keywords")
                  ? "bi bi-lock-fill"
                  : "bi bi-unlock-fill",
                onClick: (e) => {
                  toggleNotClearedOnPostField(e);
                },
              }}
            />
          </div>
          <div className="col-12 my-1">
            <div className="row w-100 d-flex justify-content-center">
              {_map(
                parseKeywordIdsFromParams(),
                (item: string) => {
                  return (
                    <ObservationKeywordBubble
                      key={`bubble-keyword-${item}`}
                      onClick={handleKeywordClick}
                      text={getCollectionItemFieldById<KeywordIndexResponse>(
                        "keywords",
                        "name",
                        item,
                        "!Rikki meni!"
                      )}
                      id={item}
                    />
                  );
                }
              )}
            </div>
          </div>
          <div className="col-12 my-1">
            {searchParams.get("language") ? (
              <CustomOptionInput
                cleanOnFocus={true}
                possibleValues={taxonSuggestions}
                labelKey={
                  searchParams.get(
                    "language"
                  ) as keyof ExtendedTaxonOptionResponse
                }
                valueKey="id"
                name="species"
                onFinishChange={(selected) => {
                  handleChangeParam(
                    "species",
                    selected?.id.toString()
                  );
                }}
                placeHolder="Laji"
                onChange={updateTaxonOptions}
                onKeyDown={(e) => {
                  moveToSubmit(e);
                }}
                valueSelected={searchParams.get("species") || ""}
                icon={{
                  icon: isInNotClearedOnPost("species")
                    ? "bi bi-lock-fill"
                    : "bi bi-unlock-fill",
                  onClick: (e) => {
                    toggleNotClearedOnPostField(e);
                  },
                }}
              />
            ) : (
              <CustomFreeInput
                cleanOnFocus={false}
                name="species"
                placeHolder="Laji"
                icon={{
                  icon: isInNotClearedOnPost("species")
                    ? "bi bi-lock-fill"
                    : "bi bi-unlock-fill",
                  onClick: (e) => {
                    toggleNotClearedOnPostField(e);
                  },
                }}
                onKeyDown={(e) => {
                  moveToSubmit(e);
                }}
                onFinishChange={handleFreeFormTaxonChange}
                valueSelected={getSpeciesNameById(
                  searchParams.get("species") || ""
                )}
              />
            )}
          </div>
          <div className="col-12 my-1">
            <CustomFreeInput
              cleanOnFocus={false}
              isTextArea={true}
              name="additional_info"
              onFinishChange={(newValue) =>
                handleChangeParam("additional_info", newValue)
              }
              placeHolder="Lisätiedot"
              valueSelected={
                searchParams.get("additional_info") || ""
              }
              onKeyDown={(e) => {
                moveToSubmit(e);
              }}
              icon={{
                icon: isInNotClearedOnPost("additional_info")
                  ? "bi bi-lock-fill"
                  : "bi bi-unlock-fill",
                onClick: (e) => {
                  toggleNotClearedOnPostField(e);
                },
              }}
            />
          </div>
          <div className="col-12 my-2">
            <button
              onClick={() => submit()}
              id="submit-btn"
              className="w-100 submit-btn-observation"
            >
              <div className="d-flex m-0 p-0 justify-content-center align-items-center">
                {searchParams.get("edit")
                  ? "Päivitä"
                  : "Tallenna"}
                {submitting === true ? (
                  <div
                    className="spinner-border spinner-border-sm m-0 p-0 ms-3"
                    role="status"
                  ></div>
                ) : (
                  <></>
                )}
              </div>
            </button>
            {searchParams.get("edit") && (
              /**if on edit form add button to go back to add */
              <button
                onClick={() => {
                  /** reset date to current date */
                  const date = new Date()
                    .toISOString()
                    .slice(0, 10);
                  navigate(`/havainto?date=${date}`);
                }}
                id="back-btn"
                className="w-100 submit-btn-observation"
              >
                Kumoa
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ObservationEditorForm;
