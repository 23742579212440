import { useEffect } from "react";

function useCheckLastVisit(action: any, threshold: number) {
  useEffect(() => {
    const lastVisitTime =
      localStorage.getItem("lastVisitTime") || null;
    const now = new Date().getTime();

    if (lastVisitTime && !isNaN(Number(lastVisitTime))) {
      const timeSinceLastVisit = now - Number(lastVisitTime);
      if (timeSinceLastVisit > threshold) {
        action();
      }
    }

    localStorage.setItem("lastVisitTime", now.toString());
  }, [action, threshold]);
}

export default useCheckLastVisit;
