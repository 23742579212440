interface FToggleProps {
  id: string;
  name: string;
  defaultOn?: boolean;
  onChange?: (e: any) => void;
}

export default function FToggle({
  id,
  name,
  defaultOn = false,
  onChange,
}: FToggleProps) {
  return (
    <label className="switch m-0">
      <input
        defaultChecked={defaultOn}
        id={id}
        name={name}
        type="checkbox"
        onChange={onChange}
      />
      <span className="slider round"></span>
    </label>
  );
}
