import { useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DashBar from "../../Components/DashBar";
import NewNavBar from "../../Components/NewNavBar";
/**
 * Dashboard component
 * Renders top nav and application dashboard navigation with route components inside (<Outlet/>)
 * Does also check for user authentication, so we can avoid using react-router loaders, which hang untill response is processed.
 */
export default function Dashboard() {
  const history = useLocation();
  const navigate = useNavigate();

  //if path is / exact (not /havainnot etc) then redirect to /listat, problem with react router
  useEffect(() => {
    if (history.pathname === "/") {
      navigate("/listat");
    }
  }, [history]);

  return (
    <div className="container-fluid p-0 m-0">
      <NewNavBar />
      <DashBar />
      <Outlet />
    </div>
  );
}
