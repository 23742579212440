import { useState } from "react";
import { useMutation } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { applyChange } from "../Utils/apiUtils";
import { signOut } from "../Utils/generalUtils";

function ProfileChangePage() {
  const { key } = useParams<{
    key: string;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<string>("");
  const [verifyValue, setVerifyValue] = useState<string>("");
  const applyChangeMutation = useMutation(
    () => applyChange(key as string, value),
    {
      onSuccess: (data) => {
        Swal.fire({
          title: "Onnistui!",
          text: "Muutos onnistui, sinut ohjataan kirjautumaan uudelleen, jotta tiedot näkyvät käyttöliittymässä",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          signOut();
        });
      },
      onError: () => {
        Swal.fire({
          title: "Virhe!",
          text: "Muutos epäonnistui",
          icon: "error",
          confirmButtonText: "Ok",
        });
      },
    }
  );

  const operationType = (() => {
    switch (searchParams.get("type")) {
      case "email":
        return "sähköposti";
      case "password":
        return "salasana";
      case "username":
        return "käyttäjänimi";
      default:
        return "tuntematon";
    }
  })();

  return (
    <div className="container-fluid m-0 p-0">
      <div className="row w-100 m-0 p-0 main-container justify-content-center py-5 ">
        <div className="col-12 col-sm-8 col-md-4 bg-white profile-container border-general py-3">
          <h5 className="text-center">
            Profiili - Muuta {operationType.toLowerCase()}
          </h5>
          <hr />
          <div className="row w-100 p-0 m-0">
            <p className="text-center">
              <b>Syötä uusi {operationType.toLowerCase()}</b>
            </p>
            <div className="col-12">
              <input
                onChange={(e: any) => {
                  setValue(e.target.value);
                }}
                type={
                  operationType === "salasana"
                    ? "password"
                    : "text"
                }
                className="form-control m-0"
                placeholder={`uusi ${operationType.toLowerCase()}`}
              />
            </div>
            <div className="col-12 my-2">
              <input
                onChange={(e: any) => {
                  setVerifyValue(e.target.value);
                }}
                type={
                  operationType === "salasana"
                    ? "password"
                    : "text"
                }
                className="form-control m-0"
                placeholder={`vahvista ${operationType.toLowerCase()}`}
              />
            </div>
            <div className="col-12">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (value !== verifyValue) {
                    Swal.fire({
                      title: "Virhe!",
                      text: `${operationType.toLowerCase()} eivät täsmää`,
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                    return;
                  }
                  applyChangeMutation.mutate();
                }}
                className="py-1 w-100"
              >
                Lähetä
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileChangePage;
