import "./styles.scss";

function LoadingSpinner({ small }: { small?: boolean }) {
  return small ? (
    <div className="lds-ellipsis lds-ellipsis-small">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default LoadingSpinner;
