import ls from "local-storage";
import { ChangeEvent } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../Images/logo.svg";
import { LoginRequest } from "../../Types/apiRequest";
import { LoginResponse } from "../../Types/apiResponses";
import { login } from "../../Utils/apiUtils";
import { parseFormData } from "../../Utils/generalUtils";

export default function LoginPage() {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    const data = response.data as LoginResponse;
    setLocalStorageData(data);
    queryClient.invalidateQueries("token");
    handleRedirect();
  };

  const handleError = (error: any) => {
    Swal.fire({
      title: "Kirjautuminen epäonnistui",
      text: "Tarkista käyttäjätunnus ja salasana",
      icon: "error",
      confirmButtonText: "Ok",
    });
  };

  const setLocalStorageData = (data: any) => {
    ls("token", data.authorization.token);
    const expireDate = calculateExpireDate(
      data.authorization.expires_in
    );
    ls("token_expire", expireDate);
    ls("user", data.user);
  };

  const calculateExpireDate = (expires_in: any) => {
    const now = new Date();
    const expireTime = now.getTime() + expires_in * 1000;
    return new Date(expireTime);
  };

  const handleRedirect = () => {
    const redirect = searchParams.get("redirect");
    navigate(redirect || "/listat");
  };

  const loginMutation = useMutation(login, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const handleSumbit = (event: ChangeEvent<HTMLFormElement>) => {
    const formContent = parseFormData(event) as LoginRequest;
    loginMutation.mutate({
      password: formContent.password,
      username: formContent.username,
    });
  };

  return (
    <div className="login-bg">
      <div className="m-0 p-0 position-fixed top-50 start-50 translate-middle w-100">
        <div className="row justify-content-center">
          <div className="shadow-md login-page-input-container bg-white col-10 col-sm-6 col-md-5 col-xl-3 text-center px-5 py-3">
            <h5 className="m-0 p-0 text-center mb-3">
              Bongariliitto
            </h5>
            <div className="w-100 d-flex justify-content-center align-items-center mb-4">
              <img
                src={logo}
                alt="Bongariliitto logo"
                width={60}
                height={60}
              />
              <h1
                style={{ fontSize: "4em" }}
                className="m-0 p-0"
              >
                Pinnari
              </h1>
            </div>
            <div className="fluid">
              <form
                method="post"
                id="login-form"
                onSubmit={handleSumbit}
              >
                <label className="w-100">
                  <p className="text-start m-0 p-0">
                    Käyttäjänimi:
                  </p>
                  <input
                    className="w-100 p-1 login-input"
                    type="text"
                    id="username"
                    name="username"
                  />
                </label>
                <label className="w-100">
                  <p className="text-start m-0 p-0">Salasana:</p>
                  <input
                    className="w-100 p-1 login-input"
                    type="password"
                    id="password"
                    name="password"
                  />
                </label>

                <div className="my-2">
                  <a href={"/vaihda-salasana"}>
                    Unohtuiko salasana?
                  </a>
                </div>

                <div className="my-2">
                  <a
                    href={
                      "https://bongariliitto.fi/index.php/liittyminen/"
                    }
                  >
                    Mistä saan käyttäjän?
                  </a>
                </div>
                <button className="w-100" type="submit">
                  Kirjaudu
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
